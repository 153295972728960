import { action, computed, observable, reaction, autorun } from "mobx";
import { MultiSelectStore } from "@web/stores";
import {
  DocumentModel,
  EntryModel,
  RemoteData,
  SectionModel,
  SelectedTagsMap,
} from "@web/models";
import { MissingClassificationsResponse } from "@web/api/Integration/types";

type BucketOptions = {
  maxSelection: number;
};

type SupportedObjects = DocumentModel | EntryModel;

export abstract class BucketModel<T extends SupportedObjects = never> {
  @observable readonly options: BucketOptions;
  @observable action?: "Move" | "Edit" | "Copy";
  @observable moveActionSubType?: "MoveToFolder" | "MoveToSection";
  @observable inProgress = false;
  @observable items: T[] = [];
  @observable isSingleAction = false;

  lastItemCount = 0;

  constructor(
    protected readonly store: MultiSelectStore,
    options: BucketOptions = { maxSelection: 1000 }
  ) {
    this.options = options;

    autorun(() => {
      if (this.itemCount === 0) {
        this.cancelAction();
      }
    });
  }

  @computed
  get hasSelection() {
    return this.items.length > 0;
  }

  @computed
  get itemCount() {
    return this.items.length;
  }

  @computed
  get allIds(): UUID[] {
    return this.items.map((item) => item.uuid);
  }

  @computed
  get lastSelected(): T | undefined {
    return this.items[this.items.length - 1];
  }

  @computed
  get canUpdateSelection(): boolean {
    return this.items.every((item) => item.canUpdate);
  }

  @computed
  get canDeleteSelection(): boolean {
    return this.items.every((item) => item.canDelete);
  }

  @computed
  get canAddToSelection() {
    return (
      this.store.entries.action !== "Move" &&
      this.store.documents.action !== "Move" &&
      this.store.documents.action !== "Copy"
    );
  }

  @action.bound
  setAction(ac: "Move" | "Edit" | "Copy", singleAction?: boolean) {
    this.action = ac;
    if (singleAction) {
      this.isSingleAction = true;
    }
  }

  @action.bound
  specifyMoveAction(ac: "MoveToSection" | "MoveToFolder") {
    this.moveActionSubType = ac;
  }

  @action.bound
  cancelAction() {
    this.action = undefined;
    this.moveActionSubType = undefined;
    if (this.isSingleAction) {
      this.clearSelection();
    }
  }

  @action
  clearSelection = () => {
    if (this.action === "Move" || this.action === "Copy") {
      return;
    }
    this.items = [];
    this.action = undefined;
    this.moveActionSubType = undefined;
    this.isSingleAction = false;
  };

  hasItem(itemId: UUID) {
    return this.allIds.includes(itemId);
  }

  @action
  addToSelection = (item: T) => {
    if (this.items.some((e) => e.id === item.id)) {
      return;
    }
    this.items.push(item);
    this.lastItemCount = this.items.length;
  };

  @action
  removeFromSelection = (item: T) => {
    this.items = this.items.filter((e) => e.id !== item.id);
    if (this.items.length > 0) {
      this.lastItemCount = this.items.length;
    }
  };

  @action
  updateSelection = (item: T, selected: boolean) => {
    if (selected) {
      this.addToSelection(item);
    } else {
      this.removeFromSelection(item);
    }
  };

  addRangeToSelection = (from: T, to: T, set: T[]): boolean => {
    const fromIndex = set.findIndex((item) => item.uuid === from.uuid);
    const toIndex = set.findIndex((item) => item.uuid === to.uuid);

    if (this.options.maxSelection <= this.itemCount) {
      return false;
    }

    let selectionRange;

    if (fromIndex <= toIndex) {
      selectionRange = set
        .slice(fromIndex, toIndex + 1)
        .slice(0, this.options.maxSelection);
    } else {
      selectionRange = set
        .slice(toIndex, fromIndex + 1)
        .reverse()
        .slice(0, this.options.maxSelection);
    }

    selectionRange.forEach((e) => this.addToSelection(e as T));

    return true;
  };
}

export class DocumentBucket extends BucketModel<DocumentModel> {
  @computed
  get entryUuid() {
    return this.items.length > 0 ? this.items[0].entryUuid : undefined;
  }

  @computed
  get entryInternalId() {
    return this.items.length > 0 ? this.items[0].entryId : undefined;
  }

  deleteSelection = () => {
    return this.store.deleteDocumentSelection(this);
  };

  copySelection(to: EntryModel) {
    return this.store.copyDocumentsToEntry(this, to);
  }

  moveSelectionToFolder(to: EntryModel) {
    return this.store.moveDocumentsToEntry(this, to);
  }

  moveSelectionToSection(to: SectionModel) {
    return this.store.moveDocumentsToSection(this, to);
  }
}

export class EntryBucket extends BucketModel<EntryModel> {
  @observable selectedTags = new SelectedTagsMap();
  @observable missingTags: RemoteData<MissingClassificationsResponse> = {
    status: "NOT_REQUESTED",
  };

  @computed
  get sectionId() {
    return this.items.length > 0 ? this.items[0].sectionId : undefined;
  }

  @computed
  get hasOnlySingleEntriesSelected(): boolean {
    const multiDocumentEntryOrDocument = this.items.find((item) => {
      return !item.isSingleDocumentEntry;
    });

    return !multiDocumentEntryOrDocument;
  }

  itemCountReaction = reaction(
    () => this.items.length,
    (count) => {
      if (count === 0) {
        this.selectedTags = new SelectedTagsMap();
      } else {
        this.selectedTags.replace(this.items.map((e) => e.tags));
      }
    }
  );

  sectionChangeReaction = reaction(
    () => this.sectionId,
    async (id) => {
      if (id) {
        this.selectedTags.requiredTags = await this.store.requiredTagsLoaded(
          id
        );
      }
    }
  );

  deleteSelection = () => {
    return this.store.deleteEntrySelection(this);
  };

  moveSelectionToSection = (to: SectionModel) => {
    return this.store.moveEntriesToSection(this, to);
  };

  moveSelectionToFolder = (to: EntryModel) => {
    return this.store.moveSingleDocumentEntriesToEntry(this, to);
  };

  findMissingTags = (sectionId: UUID) => {
    return this.store.findMissingTags(this, sectionId);
  };

  saveTagChanges = (classificationId: UUID) => {
    return this.store.applyTagsToEntries(this, classificationId);
  };

  applyTagChanges = (classificationId: UUID) => {
    this.items.forEach((entry) => {
      entry.tags.applyUnsavedChangesFrom(
        classificationId,
        this.selectedTags.changes
      );
    });
    this.selectedTags.clearUnsavedChanges();
    this.selectedTags.replace(this.items.map((e) => e.tags));
  };

  removeMissingTags = () => {
    if (this.missingTags.status !== "SUCCESS") {
      return;
    }

    const allClassificationIds = this.missingTags.result.data.flatMap(
      (cl) => cl.id
    );

    this.items.forEach((entry) => entry.tags.clear(...allClassificationIds));

    this.missingTags = { status: "NOT_REQUESTED" };
  };
}
