import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import {
  EntryBucket,
  EntryModel,
  SectionModel,
  TagSearchModel,
} from "@web/models";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { Button, CloseButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { ClassEdit, Dialog } from "@web/components";
import { ActionMenu } from "@web/elements";
import { AddTagsIcon, MoveIcon } from "@web/elements/Icons";
import * as Bucket from "./styles";
import { texts } from "./texts";
import {
  TagsToBeRemovedText,
  texts as moveCardTexts,
} from "@web/components/TagsToBeRemovedText";
import { Menu, MenuItem } from "@web/components/Menu";
import { Dropdown } from "@web/components/Dropdown";

interface Props {
  bucket: EntryBucket;
  tagSearch: TagSearchModel;
  openSection?: SectionModel;
  openEntry?: EntryModel;
  onClose: () => void;
}

export const EntryBucketOverlay: FC<Props> = observer((p) => {
  const intl = useIntl();
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [getConfirmation] = useConfirmationDialog();
  const currentEntryId = p.openEntry?.uuid;

  const isValidTargetEntry =
    currentEntryId &&
    !p.bucket.items.find((entry) => entry.uuid === currentEntryId);

  const hasChangedSection = p.bucket.sectionId !== p.openSection?.id;

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: texts.dialog_delete_title,
      message: texts.dialog_delete_entry_message,
      confirmText: commonTexts.delete,
      values: {
        count: p.bucket.itemCount,
      },
    });

    if (confirmed) {
      p.bucket.deleteSelection();
    }
  };

  const handleMoveConfirm = () => {
    if (!p.openSection) {
      return;
    }

    p.bucket.findMissingTags(p.openSection.uuid);
    setShowMoveDialog(true);
  };

  const handleMoveToSectionApply = () => {
    setShowMoveDialog(false);
    p.bucket.moveSelectionToSection(p.openSection!);
  };

  const handleCancelAction = () => {
    p.bucket.cancelAction();

    if (hasChangedSection) {
      p.bucket.clearSelection();
    }
  };

  if (!p.openSection) {
    return null;
  }

  const classifications = p.openSection.classifications;
  const canTag = p.bucket.canUpdateSelection && classifications.length > 0;
  const canMove = p.bucket.canUpdateSelection;
  const onlySingleDocumentEntries = p.bucket.hasOnlySingleEntriesSelected;
  const canDelete = p.bucket.canDeleteSelection;
  const count = p.bucket.itemCount;

  const handleMoveToFolder = () => {
    p.bucket.setAction("Move");
    p.bucket.specifyMoveAction("MoveToFolder");
  };

  const handleMoveToSection = async () => {
    const confirmed = await getConfirmation({
      title: texts.move_documents_dialog_confirm_title,
      message: texts.move_documents_dialog_confirm_message,
    });

    if (confirmed) {
      p.bucket.setAction("Move");
      p.bucket.specifyMoveAction("MoveToSection");
    }
  };

  const handleMoveToFolderConfirm = () => {
    if (!p.openEntry) {
      return;
    }

    p.bucket.moveSelectionToFolder(p.openEntry);
  };

  const handleCancelMoveToFolderAction = () => {
    p.bucket.cancelAction();

    if (!p.openEntry) {
      p.bucket.clearSelection();
    }
  };

  return (
    <>
      {p.bucket.action === undefined && (
        <Bucket.Wrap>
          <Bucket.Title>
            <CloseButton
              text={texts.tooltip_clear}
              variant="secondary"
              onClick={p.onClose}
            />
            {intl.formatMessage(texts.title_entry, {
              count: count || p.bucket.lastItemCount,
            })}
          </Bucket.Title>
          <Bucket.Actions>
            {canDelete && (
              <Button
                variant="secondary"
                icon="DeleteIcon"
                size={1.125}
                onClick={handleDelete}
                text={texts.tooltip_delete}
              />
            )}
            {canMove && !onlySingleDocumentEntries && (
              <Button
                variant="secondary"
                icon="MoveIcon"
                size={1.125}
                onClick={handleMoveToSection}
                text={texts.tooltip_move}
              />
            )}
            {canMove && onlySingleDocumentEntries && (
              <Dropdown
                toggle={(ref) => (
                  <Button
                    icon="MoveIcon"
                    size={1.125}
                    variant="secondary"
                    text={commonTexts.move}
                    ref={ref}
                  />
                )}
              >
                <Menu>
                  <MenuItem onClick={handleMoveToSection}>
                    {intl.formatMessage(texts.tooltip_move_to_section)}
                  </MenuItem>
                  <MenuItem onClick={handleMoveToFolder}>
                    {intl.formatMessage(texts.tooltip_move_to_folder)}
                  </MenuItem>
                </Menu>
              </Dropdown>
            )}
            {canTag && (
              <Button
                variant="secondary"
                icon="AddTagsIcon"
                size={1.125}
                onClick={() => p.bucket.setAction("Edit")}
                text={texts.tooltip_tag}
              />
            )}
          </Bucket.Actions>
        </Bucket.Wrap>
      )}

      {p.bucket.action === "Edit" && (
        <Bucket.Wrap>
          <Bucket.Title>
            <AddTagsIcon />
            {intl.formatMessage(texts.title_tags, { count })}
          </Bucket.Title>
          <Bucket.Tags>
            <ClassEdit
              editable={p.bucket.canUpdateSelection}
              labelVariant="outline"
              classifications={classifications}
              tagSearch={p.tagSearch}
              selectedTags={p.bucket.selectedTags}
              onApply={p.bucket.saveTagChanges}
            />
          </Bucket.Tags>
          <Bucket.Actions>
            <Button
              text={commonTexts.done}
              variant="secondary"
              onClick={p.bucket.cancelAction}
            />
          </Bucket.Actions>
        </Bucket.Wrap>
      )}

      {p.bucket.action === "Move" &&
        p.bucket.moveActionSubType === "MoveToFolder" && (
          <Bucket.Wrap attention={!isValidTargetEntry}>
            <Bucket.Title>
              <MoveIcon />
              {intl.formatMessage(
                isValidTargetEntry
                  ? texts.title_move_document
                  : texts.title_choose_entry,
                { count }
              )}
            </Bucket.Title>
            <Bucket.Actions>
              <Button
                variant="blank"
                text={commonTexts.cancel}
                onClick={handleCancelMoveToFolderAction}
              />
              {canMove && isValidTargetEntry && (
                <Button
                  variant="secondaryInverted"
                  text={commonTexts.moveHere}
                  onClick={handleMoveToFolderConfirm}
                />
              )}
            </Bucket.Actions>
          </Bucket.Wrap>
        )}

      {p.bucket.action === "Move" &&
        p.bucket.moveActionSubType === "MoveToSection" && (
          <Bucket.Wrap attention={!hasChangedSection}>
            <Bucket.Title>
              <MoveIcon />
              {intl.formatMessage(
                hasChangedSection
                  ? texts.title_move_entry
                  : texts.title_choose_section,
                { count }
              )}
            </Bucket.Title>
            <Bucket.Actions>
              <Button
                text={commonTexts.cancel}
                variant="blank"
                onClick={handleCancelAction}
              />
              {hasChangedSection && (
                <Button
                  text={commonTexts.moveHere}
                  variant="secondaryInverted"
                  onClick={handleMoveConfirm}
                />
              )}
            </Bucket.Actions>
          </Bucket.Wrap>
        )}

      {showMoveDialog && (
        <Dialog title={texts.dialog_move_entry_title} values={{ count }}>
          <TagsToBeRemovedText
            missingTags={p.bucket.missingTags}
            selectedSection={p.openSection}
            selectedCardsCount={count}
          />
          <p>
            {intl.formatMessage(moveCardTexts.accessWarning, {
              cardsCount: count,
            })}
          </p>

          <ActionMenu
            applyText={commonTexts.moveHere}
            applyIsDisabled={p.bucket.missingTags.status === "LOADING"}
            onCancel={() => setShowMoveDialog(false)}
            onApply={handleMoveToSectionApply}
          />
        </Dialog>
      )}
    </>
  );
});
