import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { components, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { defineMessages, useIntl } from "react-intl";
import { ITagNodeExpanded } from "@web/api/Integration/types";
import { Overlay } from "@web/components";
import { ActionMenu } from "@web/elements";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import { selectTheme } from "@web/styles/DMReactSelectStyle";

interface IEvents {
  findTags: (query: string) => Promise<ITagNodeExpanded[]>;
  onAddDone: (tag: ITagNodeExpanded) => void;
  onAddCancel: () => void;
}

interface IProps extends IEvents {
  sectionId?: string;
}

interface SelectOption {
  label: string;
  value: ITagNodeExpanded;
  isDisabled?: boolean;
}

export const SelectTagBox: React.FC<IProps> = observer((props) => {
  const intl = useIntl();
  const [selectedTag, setSelectedTag] = useState<ITagNodeExpanded>();
  const canSave = selectedTag !== undefined;

  function handleSave(doSave: boolean) {
    const { onAddDone, onAddCancel } = props;
    if (doSave && selectedTag) {
      onAddDone(selectedTag);
    } else {
      onAddCancel();
    }
  }

  function handleSelectChange(selected: SingleValue<SelectOption>) {
    setSelectedTag(selected?.value);
  }

  async function loadOptions(inputValue: string) {
    const res = await props.findTags(inputValue);
    return res.map((tag) => ({
      value: tag,
      label: tag.title,
      subLabel: tag.classification.title,
    }));
  }

  return (
    <Overlay>
      <_wrap>
        <h3>{intl.formatMessage(texts.title)}</h3>
        <_inputWrap>
          <AsyncSelect
            autoFocus
            isClearable
            theme={selectTheme}
            placeholder={intl.formatMessage(texts.typeToFindTag)}
            loadOptions={loadOptions}
            onChange={handleSelectChange}
            loadingMessage={({ inputValue }) =>
              intl.formatMessage(texts.lookingFor, {
                value: inputValue,
              })
            }
            noOptionsMessage={({ inputValue }) =>
              inputValue.length > 0
                ? intl.formatMessage(commonTexts.noMatches)
                : null
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: (props) => (
                <components.Option {...props}>
                  <div>{props.data.label}</div>
                  <_optionSubtitle>
                    {intl.formatMessage(texts.belongsToSections, {
                      classification: props.data.subLabel,
                    })}
                  </_optionSubtitle>
                </components.Option>
              ),
            }}
          />
        </_inputWrap>

        <ActionMenu
          onApply={() => handleSave(true)}
          onCancel={() => handleSave(false)}
          applyIsDisabled={!canSave}
          applyText={commonTexts.select}
        />
      </_wrap>
    </Overlay>
  );
});

const _wrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 350px;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  input {
    border-radius: 3px;
  }
  h3 {
    margin: 0px;
    font-size: 16px;
  }
`;

const _inputWrap = styled.div`
  margin: 10px 0px;
`;

const _optionSubtitle = styled.div`
  font-size: 0.8em;
  padding-top: 2px;
  color: ${vars.dark55};
`;

const texts = defineMessages({
  title: {
    id: "tag.selectbox.title",
    defaultMessage: "Find tag",
  },
  lookingFor: {
    id: "tag.selectbox.lookingfor",
    defaultMessage: 'Looking for "{value}"',
  },
  typeToFindTag: {
    id: "tag.selectbox.typetofindtag",
    defaultMessage: "Type to find a tag…",
  },
  belongsToSections: {
    id: "tag.selectbox.belongstolist",
    defaultMessage: "From list: {classification}",
  },
});
