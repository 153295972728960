import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { IOutcomeNode } from "@web/api/Integration/types";
import { ENTRY_STATUS_COLORS } from "@web/components/EntryStatus/types";
import { OUTCOME_OPTIONS } from "@web/components/Flow/helpers";
import { InfoText, StatusColor } from "@web/elements";
import {
  EntryStatusModel,
  EntryStatusName,
  OutcomeEventType,
  PipelineModel,
  isCommentOutcome,
  isNotificationOutcome,
  isStatusOutcome,
} from "@web/models";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { CreateButton } from "../CreateButton";
import { RemoveButton } from "../RemoveButton";
import { AddOutcome } from "./AddOutcome";
import { EditOutcome } from "./EditOutcome";

interface IProps {
  pipeline: PipelineModel;
  entryStatuses: EntryStatusModel[];
}

export const OutcomeList: FC<IProps> = observer((p) => {
  const intl = useIntl();

  const [getConfirmation] = useConfirmationDialog();
  const [selectedOutcome, selectOutcome] = useState<IOutcomeNode>();
  const [addOutcome, setAddOutcome] = useState<OutcomeEventType>();

  const handleDelete = async (outcome: IOutcomeNode) => {
    const confirmed = await getConfirmation({
      title: texts.deleteConfirmationTitle,
      message: texts.deleteConfirmationMessage,
    });

    if (confirmed) {
      p.pipeline.deleteOutcome(outcome.id);
    }
  };

  const renderOutcomeDetails = (outcome: IOutcomeNode) => {
    if (isCommentOutcome(outcome)) {
      return (
        <_notOverlappingDiv>
          <i>{`"${outcome.entryComment}"`}</i>
        </_notOverlappingDiv>
      );
    }

    if (isStatusOutcome(outcome)) {
      const status = p.entryStatuses.find(
        (c) => c.uuid === outcome.entryStatus
      )?.name;

      if (status) {
        return (
          <StatusColor color={ENTRY_STATUS_COLORS[status as EntryStatusName]} />
        );
      }

      return intl.formatMessage(texts.noMatchingColor);
    }

    if (isNotificationOutcome(outcome)) {
      return (
        <_notOverlappingDiv>
          {outcome.isSensitive
            ? intl.formatMessage(texts.sensitive)
            : outcome.webhookURL}
        </_notOverlappingDiv>
      );
    }
  };

  return (
    <_wrap>
      <InfoText>
        <FormattedMessage {...texts.infoText} values={{ br: <br /> }} />
      </InfoText>

      <_header>
        <h3>
          <FormattedMessage
            {...texts.validationSuccessful}
            values={{ count: p.pipeline.successOutcomes.length }}
          />
        </h3>
        <CreateButton onClick={() => setAddOutcome("pipelineSuccess")} />
      </_header>

      {addOutcome === "pipelineSuccess" && (
        <AddOutcome
          pipeline={p.pipeline}
          eventType="pipelineSuccess"
          entryStatuses={p.entryStatuses}
          onClose={() => setAddOutcome(undefined)}
        />
      )}

      {p.pipeline.successOutcomes.map((outcome) => (
        <React.Fragment key={outcome.id}>
          {selectedOutcome?.id === outcome.id ? (
            <EditOutcome
              pipeline={p.pipeline}
              entryStatuses={p.entryStatuses}
              outcome={outcome}
              onClose={() => selectOutcome(undefined)}
            />
          ) : (
            <_outcome onClick={() => selectOutcome(outcome)}>
              <div>
                <FormattedMessage {...OUTCOME_OPTIONS[outcome.type]} />
              </div>
              {renderOutcomeDetails(outcome)}
              <RemoveButton
                onClick={() => handleDelete(outcome)}
                disabled={p.pipeline.successOutcomes.length < 2}
              />
            </_outcome>
          )}
        </React.Fragment>
      ))}

      {p.pipeline.pipelineType === "validation" && (
        <>
          <_header>
            <h3>
              <FormattedMessage
                {...texts.validationUnsuccessful}
                values={{ count: p.pipeline.failureOutcomes.length }}
              />
            </h3>
            <CreateButton onClick={() => setAddOutcome("pipelineFailure")} />
          </_header>
          {addOutcome === "pipelineFailure" && (
            <AddOutcome
              pipeline={p.pipeline}
              eventType="pipelineFailure"
              entryStatuses={p.entryStatuses}
              onClose={() => setAddOutcome(undefined)}
            />
          )}

          {p.pipeline.failureOutcomes.map((outcome) => (
            <React.Fragment key={outcome.id}>
              {selectedOutcome?.id === outcome.id ? (
                <EditOutcome
                  pipeline={p.pipeline}
                  entryStatuses={p.entryStatuses}
                  outcome={outcome}
                  onClose={() => selectOutcome(undefined)}
                />
              ) : (
                <_outcome onClick={() => selectOutcome(outcome)}>
                  <div>
                    <FormattedMessage {...OUTCOME_OPTIONS[outcome.type]} />
                  </div>
                  {renderOutcomeDetails(outcome)}
                  <RemoveButton
                    onClick={() => handleDelete(outcome)}
                    disabled={p.pipeline.successOutcomes.length < 2}
                  />
                </_outcome>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h3 {
    margin: 5px 0px 10px 0px;
  }
`;

const _outcome = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;

  > div:first-child {
    flex-shrink: 0;
    padding-right: 20px;
  }
`;

const _header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px 5px 0px;
`;

const _notOverlappingDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const texts = defineMessages({
  deleteConfirmationTitle: {
    id: "flow.settings.outcome.delete.title",
    defaultMessage: "Are you sure you want to do this?",
  },
  deleteConfirmationMessage: {
    id: "flow.settings.outcome.delete.message",
    defaultMessage: "This will permanently delete the outcome.",
  },
  noMatchingColor: {
    id: "flow.settings.outcome.no.matching.color",
    defaultMessage: "No matching color",
  },
  sensitive: {
    id: "flow.settings.outcome.sensitive",
    defaultMessage: "Sensitive",
  },
  infoText: {
    id: "flow.settings.outcome.info.text",
    defaultMessage:
      "Outcomes determine what should happen after a folder has been validated.{br}<strong>Note</strong>: a checklist is required to have at least one success outcome.",
  },
  validationSuccessful: {
    id: "flow.settings.outcome.validation.successful",
    defaultMessage: "When validation was successful ({count})",
  },
  validationUnsuccessful: {
    id: "flow.settings.outcome.validation.unsuccessful",
    defaultMessage: "When validation was unsuccessful ({count})",
  },
});
