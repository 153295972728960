import React, { useEffect, useRef } from "react";
import { default as styled } from "styled-components";
import { IntlProvider, defineMessages, useIntl } from "react-intl";
import { GlobalStyle } from "@web/styles";
import { Button } from "@web/elements/Button";
import { useConfig } from "@web/config/context";

interface LoginProps {
  loginUrl: string;
}

export const LoginPage = ({ loginUrl }: LoginProps) => {
  const config = useConfig();
  const intl = useIntl();

  const popupRef = useRef<Window | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleLoginClick = () => {
    if (popupRef.current && timeoutRef.current) {
      // A Login popup has already been opened.
      // Clear the previous timeout before proceeding.
      clearInterval(timeoutRef.current);
    }

    // The url to return to
    const returnUrl = new URL(location.href);
    returnUrl.pathname = "/";
    returnUrl.searchParams.set("isLoginPopup", "1");

    // The url to open in the popup
    const popupUrl = new URL(loginUrl);
    popupUrl.searchParams.set("requestedUrl", returnUrl.toString());
    popupRef.current = window.open(popupUrl.toString(), "_blank");

    timeoutRef.current = setInterval(() => {
      try {
        // When we can access the popup's location and its origin is
        // the same as this window, (but not the idp login url)
        // we know login was successful and we can redirect to '/' to reload.
        const popup = popupRef.current;
        if (!popup) {
          return;
        }

        const isLoggedIn =
          popup.location.origin === location.origin &&
          popup.location.pathname.indexOf("idp/oauth2/auth") === -1;
        if (isLoggedIn) {
          popup.close();
          location.href = "/";
        }
      } catch (err) {
        // Error means popup is on an inaccessible origin,
        // so just ignore it and wait for next try...
      }
    }, 50);
  };

  useEffect(() => {
    // Clear timeout on unmount
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  return (
    <IntlProvider locale={config.locale}>
      <GlobalStyle />
      <_wrap>
        <Button
          text={intl.formatMessage(
            { ...texts.loginButton },
            { instance: config.instance }
          )}
          variant="primary"
          onClick={handleLoginClick}
        />
      </_wrap>
    </IntlProvider>
  );
};

const _wrap = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  grid-gap: 1rem;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
  width: 100vw;
  height: 100vh;
`;

const texts = defineMessages({
  loginButton: {
    id: "login.button",
    defaultMessage: "{instance} login",
  },
});
