import {
  EntryModel,
  DocumentModel,
  AttributeValueModel,
  CommentModel,
  DocumentVersionModel,
} from "@web/models";

export interface Subscriber {
  onDataAdded?: (event: AddEvent) => void;
  onDataUpdated?: (event: UpdateEvent) => void;
  onDataDeleted?: (event: DeleteEvent) => void;
}

interface BaseEvent {
  type: string;
}

export type AddEntryEventContext = undefined | "draft-entry";

interface AddEntryEvent extends BaseEvent {
  type: "Entry";
  data: EntryModel;
  context?: AddEntryEventContext;
}

export interface UpdateEntryEvent extends BaseEvent {
  type: "Entry";
  data: EntryModel;
  fields?: Array<keyof EntryModel>;
}

interface DeleteEntryEvent extends BaseEvent {
  type: "Entry";
  uuid: UUID;
}

interface AddDocumentEvent extends BaseEvent {
  type: "Document";
  data: DocumentModel;
}

interface AddDocumentVersionEvent extends BaseEvent {
  type: "DocumentVersion";
  data: DocumentVersionModel;
}

interface UpdateDocumentEvent extends BaseEvent {
  type: "Document";
  data: DocumentModel;
}

interface DeleteDocumentEvent extends BaseEvent {
  type: "Document";
  uuid: UUID;
  entryId: number;
  entryUuid: UUID;
}

interface DeleteDocumentVersionEvent extends BaseEvent {
  type: "DocumentVersion";
  uuid: UUID;
  documentUuid: UUID;
}

interface AddCommentEvent extends BaseEvent {
  type: "Comment";
  data: CommentModel;
}
interface DeleteCommentEvent extends BaseEvent {
  type: "Comment";
  uuid: UUID;
}

interface AddAttributeEvent extends BaseEvent {
  type: "AttributeValue";
  data: AttributeValueModel;
}

interface UpdateAttributeValueEvent extends BaseEvent {
  type: "AttributeValue";
  data: AttributeValueModel;
}

interface DeleteAttributeValueEvent extends BaseEvent {
  type: "AttributeValue";
  uuid: UUID;
}

export type AddEvent =
  | AddEntryEvent
  | AddDocumentEvent
  | AddDocumentVersionEvent
  | AddAttributeEvent
  | AddCommentEvent;

export type UpdateEvent =
  | UpdateEntryEvent
  | UpdateDocumentEvent
  | UpdateAttributeValueEvent;

export type DeleteEvent =
  | DeleteEntryEvent
  | DeleteDocumentEvent
  | DeleteDocumentVersionEvent
  | DeleteCommentEvent
  | DeleteAttributeValueEvent;

export default class PubSub {
  private static instance: PubSub;
  private subscribers: Subscriber[] = [];

  static getInstance(): PubSub {
    if (!PubSub.instance) {
      PubSub.instance = new PubSub();
    }

    return PubSub.instance;
  }
  // constructor with a private access modifier, so that it isn’t accessible outside of the class body,
  private constructor() {}

  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber);
    return () => {
      this.subscribers = this.subscribers.filter(
        (_subscriber) => _subscriber !== subscriber
      );
    };
  }

  clearSubscribers() {
    this.subscribers = [];
  }

  notifyUpdate(event: UpdateEvent) {
    console.debug(
      `[PubSub] ${event.type} updated${
        event.type === "Entry" ? ", fields: " + event.fields : ""
      }`
    );
    for (const subscriber of this.subscribers) {
      try {
        subscriber.onDataUpdated?.(event);
      } catch (error) {
        console.error(
          "[PubSub] Caught error when notifying about event:",
          error
        );
      }
    }
  }

  notifyDelete(event: DeleteEvent) {
    console.debug(`[PubSub] ${event.type} ${event.uuid} deleted`);
    for (const subscriber of this.subscribers) {
      try {
        subscriber.onDataDeleted?.(event);
      } catch (error) {
        console.error(
          "[PubSub] Caught error when notifying about event:",
          error
        );
      }
    }
  }

  notifyAdd(event: AddEvent) {
    console.debug(`[PubSub] ${event.type} added: ${event.data.uuid}`);
    for (const subscriber of this.subscribers) {
      try {
        subscriber.onDataAdded?.(event);
      } catch (error) {
        console.error(
          "[PubSub] Caught error when notifying about event:",
          error
        );
      }
    }
  }
}
