import React, { ChangeEvent, FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages } from "react-intl";
import { PipelineModel } from "@web/models";
import { InfoText } from "@web/elements";

interface IProps {
  pipeline: PipelineModel;
}

export const EditPipelineEvents: FC<IProps> = observer((p) => {
  const handleEventSelect = (e: ChangeEvent<HTMLInputElement>) => {
    p.pipeline?.updateEvents(e.target.value, e.target.checked);
  };

  return (
    <div>
      <InfoText>
        <FormattedMessage {...texts.infoText} values={{ br: <br /> }} />
      </InfoText>
      <_events>
        {p.pipeline.supportedEvents.map((event) => (
          <_eventOption key={event.id}>
            <input
              type="checkbox"
              id={event.id}
              value={event.id}
              defaultChecked={p.pipeline?.eventIds.includes(event.id)}
              onChange={handleEventSelect}
              disabled={
                p.pipeline?.eventIds.length === 1 &&
                p.pipeline?.eventIds.includes(event.id)
              }
            />
            <label htmlFor={event.id}>{event.name}</label>
          </_eventOption>
        ))}
      </_events>
    </div>
  );
});

const _events = styled.div`
  margin-top: 15px;
`;

const _eventOption = styled.div`
  display: inline-flex;
  margin-right: 25px;
  margin-bottom: 10px;
  align-items: center;
`;

const texts = defineMessages({
  infoText: {
    id: "flow.settings.pipeline.info.text",
    defaultMessage:
      "Events determine when the folders connected to this checklist will be validated. When creating a new checklist, a default set of events will be applied for you. If unsure, you can keep the default set.{br}<strong>Note</strong>: A checklist is required to have at least one event.",
  },
});
