import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { media, useMedia } from "@web/styles/utils";
import { CaretLeftIcon, CaretRightIcon } from "@web/elements/Icons";
import { Preview } from "@web/components/Document/Preview";
import { IconButton } from "@web/elements/Button";
import { vars } from "@web/styles";
import { SkeletonText } from "@web/elements";
import { VersionUploadDropzone } from "@web/components/Upload/VersionUploadDropzone";
import { useTabPanels } from "@web/utils/hooks/useTabPanels";
import { Tab, Tabs } from "@web/elements/Tabs";
import { useStores } from "@web/stores/context";
import { Overlay } from "@web/components";
import { KeyboardNavigation } from "@web/components/KeyboardNavigation";
import { DocumentUpdateBanner } from "@web/components/Document/DocumentUpdateBanner";
import { DocumentModel, UploadRequest } from "@web/models";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { ErrorPage } from "@web/pages/ErrorPage";
import { commonTexts } from "@web/translations";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";
import { useConfig } from "@web/config/context";
import { texts } from "@web/components/Document/texts";
import { useDocumentNavigationHandler } from "./handler";
import { DocumentSidebar } from "./DocumentSidebar";
import { DocumentTitle } from "./DocumentTitle";
import { DocumentToolbar } from "./DocumentToolbar";

type Panels = "preview" | "details" | null;

export const DocumentPage = observer(() => {
  const intl = useIntl();
  const config = useConfig();

  useDocumentNavigationHandler();
  const getConfirmation = useDuplicateUploadConfirmDialog();

  const {
    currentSearchURL,
    documentStore,
    keyboardStore,
    recordStore,
    searchStore,
    uploadStore,
  } = useStores();
  const navigate = useNavigate();
  const { isCompact, isDesktop } = useMedia();
  const { getTabProps, getPanelProps } = useTabPanels<Panels>("preview");
  const doc = documentStore.document;
  const isEntryLoaded =
    recordStore.entry && recordStore.entry.id === doc?.entryId;

  const handleClose = () => {
    if (isEntryLoaded && recordStore.entry) {
      navigate(generateEntryUrl(recordStore.entry.id));
    } else {
      navigate(currentSearchURL());
    }
  };

  const handleUpload = async (request: UploadRequest) => {
    return uploadStore.addRequest(request);
  };

  const debouncedLoadDocument = debounce(
    () => documentStore.document?.reload(),
    300,
    { leading: false, trailing: true }
  );

  const handleDocumentNavigation = (document: DocumentModel) => {
    documentStore.setDocument(document);
    // Debouncing to avoid extra calls to fetch versions when not needed.
    debouncedLoadDocument();
    navigate(`/document/${document.id}`);
  };

  const handleGotoPrevDocument = () => {
    recordStore.entry?.selectPreviousDocument();
    if (recordStore.entry?.focusedDocument) {
      handleDocumentNavigation(recordStore.entry?.focusedDocument);
    }
  };

  const handleGotoNextDocument = () => {
    recordStore.entry?.selectNextDocument();
    if (recordStore.entry?.focusedDocument) {
      handleDocumentNavigation(recordStore.entry?.focusedDocument);
    }
  };

  const isNavigationEnabled = () => {
    return documentStore.hasDocument;
  };

  if (documentStore.error) {
    return <ErrorPage error={documentStore.error} />;
  }

  if (!doc) {
    return <SkeletonDocument />;
  }

  return (
    <Overlay onEscape={handleClose}>
      <_wrap>
        <Helmet>
          <title>{`${doc.title} - ${config.instance}`}</title>
        </Helmet>

        <_header>
          <_close
            disableTooltip
            text={isEntryLoaded ? commonTexts.back : commonTexts.close}
            icon={isEntryLoaded ? "CaretLeftIcon" : "CrossIcon"}
            onClick={handleClose}
          />

          <_title>
            <DocumentTitle document={doc} />
          </_title>

          <_tools>
            <DocumentToolbar document={doc} />
          </_tools>
        </_header>

        {isCompact && (
          <Tabs>
            <Tab {...getTabProps("preview")}>
              {intl.formatMessage(texts.sectionPreviewTitle)}
            </Tab>
            <Tab {...getTabProps("details")}>
              {intl.formatMessage(texts.sectionDetailsTitle)}
            </Tab>
          </Tabs>
        )}

        <_content {...getPanelProps("preview", isCompact)}>
          {doc.hasUpdate && <DocumentUpdateBanner document={doc} />}
          <VersionUploadDropzone
            document={doc}
            onUpload={handleUpload}
            getConfirmation={getConfirmation}
          >
            <Preview
              document={doc}
              nextDocument={recordStore.entry?.nextDocument?.newestVersion}
              prevDocument={recordStore.entry?.prevDocument?.newestVersion}
              searchQuery={searchStore.getSearchQueryComponents().query}
            />
          </VersionUploadDropzone>

          {recordStore.entry?.prevDocument && (
            <_prevButton onClick={handleGotoPrevDocument}>
              <CaretLeftIcon />
            </_prevButton>
          )}

          {recordStore.entry?.nextDocument && (
            <_nextButton onClick={handleGotoNextDocument}>
              <CaretRightIcon />
            </_nextButton>
          )}
        </_content>

        <_aside {...getPanelProps("details", isCompact)}>
          {isDesktop && (
            <Tabs>
              <Tab>{intl.formatMessage(texts.tabDocumentInfoTitle)}</Tab>
            </Tabs>
          )}
          <DocumentSidebar document={doc} />
        </_aside>

        <KeyboardNavigation
          identifier="Document"
          keyboardStore={keyboardStore}
          enabled={isNavigationEnabled}
          onArrowLeft={handleGotoPrevDocument}
          onArrowRight={handleGotoNextDocument}
        />
      </_wrap>
    </Overlay>
  );
});

const SkeletonDocument = () => (
  <Overlay>
    <_wrap>
      <_header>
        <_title>
          <SkeletonText />
        </_title>
      </_header>
      <_content />
      <_aside />
    </_wrap>
  </Overlay>
);

const _wrap = styled.div`
  background: ${vars.content};
  color: ${vars.contentFg};
  display: grid;
  width: 100%;
  height: 100%;

  ${media("desktop")} {
    grid-template:
      "header header" auto
      "content aside" 1fr
      / 1fr 320px;
  }

  ${media("compact")} {
    grid-template:
      "header" auto
      "tabs" auto
      "panel" 1fr;
  }
`;

const _header = styled.header`
  grid-area: header;
  border-bottom: 2px solid ${vars.dark05};
  align-items: center;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  overflow: hidden;
`;

const _close = styled(IconButton)`
  color: ${vars.dark55};

  :hover {
    color: ${vars.contentFg};
  }
`;

const _title = styled.div`
  flex: 1;
  overflow: hidden;
`;

const _tools = styled.div`
  display: flex;
  gap: 0.5rem;

  button {
    margin: 0;
  }
`;

const _content = styled.div`
  overflow: hidden;
  position: relative;

  ${media("desktop")} {
    grid-area: content;
  }

  ${media("compact")} {
    grid-area: panel;
  }
`;

const _aside = styled.aside`
  overflow: auto;

  ${media("desktop")} {
    grid-area: aside;
    border-left: 2px solid ${vars.dark05};
  }

  ${media("compact")} {
    grid-area: panel;
  }
`;

const _navButton = styled.button`
  background: ${vars.content};
  border: none;
  border-radius: 100%;
  box-shadow: ${vars.shadow.z3};
  color: ${vars.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
`;

const _prevButton = styled(_navButton)`
  left: 0.75rem;
`;

const _nextButton = styled(_navButton)`
  right: 0.75rem;
`;
