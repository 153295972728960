import React from "react";
import { useConfig } from "@config/context";
import { UploadDropzone } from "./UploadDropzone";
import { DocumentUploadProps } from "./types";
import { handleDocumentUpload } from "./handlers";

export const DocumentUploadDropzone: React.FC<DocumentUploadProps> = (p) => {
  const { canUploadDocuments } = useConfig();
  const isDisabled = !p.entry.canAddDocument || !canUploadDocuments;

  const handleUpload = (files: File[]) => {
    handleDocumentUpload(files, p);
  };

  return (
    <UploadDropzone
      type="document"
      multiple={true}
      disabled={isDisabled}
      overlayPosition="absolute"
      onDrop={handleUpload}
    >
      {p.children}
    </UploadDropzone>
  );
};
