import React, { FC, useState } from "react";
import styled from "styled-components";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { commonTexts } from "@web/translations";
import { ActionMenu, Input } from "@web/elements";
import { Dialog } from "@web/components/Dialog";
import { WarningIcon } from "@web/elements/Icons";

// NOTE: React.createContext requires a default value, use this to issue warning if
// caller component is not wrapped in <ConfirmationDialogProvider>.
const ConfirmationDialogContext = React.createContext({
  openDialog: (config: InternalDialogConfig) => {
    console.warn(
      "<ConfirmationDialogProvider> is needed to use 'useConfirmDialog hook'"
    );
  },
});

interface DialogConfig {
  title: MessageDescriptor;
  message?: MessageDescriptor;
  values?: Record<string, React.ReactText>;
  confirmText?: MessageDescriptor;
  cancelText?: MessageDescriptor;
  warningText?: MessageDescriptor;
  confirmByInput?: string;
  disableConfirm?: boolean;
}

interface InternalDialogConfig extends DialogConfig {
  actionCallback: (confirmed: boolean) => void;
}

export const ConfirmationDialogProvider: FC = ({ children }) => {
  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<InternalDialogConfig>();
  const [input, setInput] = useState("");

  const canConfirm = () => {
    if (dialogConfig?.disableConfirm) {
      return false;
    }

    if (dialogConfig?.confirmByInput) {
      return input === dialogConfig?.confirmByInput;
    }
    return true;
  };

  const openDialog = (config: InternalDialogConfig) => {
    setDialogConfig(config);
    setDialogOpen(true);
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(undefined);
    setInput("");
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig?.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig?.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {dialogOpen && dialogConfig && (
        <Dialog
          title={dialogConfig.title}
          values={dialogConfig.values}
          onClickOutside={onDismiss}
        >
          {dialogConfig.message && (
            <div style={{ margin: "20px 0px" }}>
              {intl.formatMessage(dialogConfig.message, dialogConfig.values)}
            </div>
          )}

          {dialogConfig.warningText && (
            <_warning>
              <WarningIcon />
              {intl.formatMessage(
                dialogConfig.warningText,
                dialogConfig.values
              )}
            </_warning>
          )}

          {dialogConfig.confirmByInput && (
            <_input htmlFor="confirmation-dialog-input">
              {intl.formatMessage(texts.typeToConfirm, {
                text: dialogConfig.confirmByInput,
              })}
              <Input
                autoFocus
                id="confirmation-dialog-input"
                value={input}
                width="100%"
                onChange={(e) => setInput(e.target.value)}
              />
            </_input>
          )}

          <ActionMenu
            direction="horizontal"
            applyIsDisabled={!canConfirm()}
            onApply={onConfirm}
            onCancel={onDismiss}
            applyText={dialogConfig.confirmText || commonTexts.ok}
            cancelText={dialogConfig.cancelText || commonTexts.cancel}
          />
        </Dialog>
      )}
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }: DialogConfig) => {
    return new Promise((res) =>
      openDialog({ actionCallback: res, ...options })
    );
  };

  return [getConfirmation];
};

const _warning = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    flex-shrink: 0;
    margin-right: 5px;
  }
`;

const _input = styled.label`
  display: block;
  margin: 12px 0;

  em {
    font-weight: bold;
  }

  label {
    margin-top: 3px;
  }
`;

const texts = defineMessages({
  typeToConfirm: {
    id: "dialog.confirm.type",
    defaultMessage: "Please type <em>{text}</em> to confirm.",
  },
});
