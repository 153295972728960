import { action, computed, observable, reaction, when } from "mobx";
import { addHours } from "date-fns";
import { defineMessages } from "react-intl";
import axios from "axios";
import { Api, getRequestError } from "@web/api";
import { DocumentModel, WritableFields } from "@web/models";
import { commonTexts } from "@web/translations";
import { IDocumentNode } from "@web/api/Integration/types";
import { AppConfig } from "@web/config";
import { DocumentPreviewState } from "@web/api/BFF/types";
import PubSub, { AddEvent, DeleteEvent, Subscriber } from "./PubSub";
import commonStoreTexts from "./texts";
import { RootStore } from ".";

type DocumentUUID = UUID;

export class DocumentStore implements Subscriber {
  @observable
  documentCache = new Map<DocumentUUID, DocumentModel>();

  @observable loading = false;
  @observable error?: Error;
  @observable document?: DocumentModel;

  constructor(
    private api: Api,
    public rootStore: RootStore,
    private appConfig: AppConfig
  ) {
    reaction(
      () => this.document,
      (document) => {
        document?.prepareUiState();
      }
    );

    PubSub.getInstance().subscribe(this);
  }

  async documentLoaded() {
    await when(() => this.document !== undefined);
    this.error = undefined;
  }

  @computed
  get hasDocument() {
    return this.document !== undefined;
  }

  /**
   * Return DocumentModel object from server data.
   */
  documentFromJson = (json: IDocumentNode): DocumentModel => {
    const existing = this.documentCache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    }

    const doc = new DocumentModel(this, json, this.appConfig.loggedInUser.id);
    this.documentCache.set(doc.uuid, doc);
    return doc;
  };

  /**
   * Set current (visible) document from existing data.
   */
  @action.bound
  setDocument(document: DocumentModel) {
    if (this.document?.id === document.id) {
      return;
    }
    this.document?.resetUiState();
    this.document = document;
  }

  @action.bound
  clearDocument() {
    this.document?.resetUiState();
    this.document = undefined;
    this.error = undefined;
  }

  // A document was deleted, clean it from the client memory
  @action.bound
  removeDocument(document: DocumentModel) {
    if (this.document?.uuid === document.uuid) {
      // The deleted document is currently visible,
      // clean up UI state before proceeding.
      this.clearDocument();
    }

    this.documentCache.delete(document.uuid);
    document.destroy();
  }

  async reloadDocument(document: DocumentModel) {
    try {
      const documentAsJson = await this.api.getDocumentByNumericId(document.id);
      document.updateFromJson(documentAsJson);
      document.updateState({ hasUpdate: undefined });
    } catch (error) {
      this.handleError(getRequestError(error));
    }
  }

  /**
   * Set current (visible) document from server data.
   * Used when entering the app via a document URL.
   */
  @action.bound
  async loadDocument(documentId: number) {
    this.loading = true;

    try {
      const documentAsJson = await this.api.getDocumentByNumericId(documentId);
      this.document = this.documentFromJson(documentAsJson);
    } catch (error) {
      this.error = getRequestError(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async loadVersions(document: DocumentModel) {
    const { uuid, versionLoadingStatus } = document;
    try {
      versionLoadingStatus.pageLoading = true;
      const newPage = versionLoadingStatus.lastPageLoaded + 1;
      const { data } = await this.api.getDocumentVersions(uuid, newPage);
      document.addVersionsFromJson(data);
    } catch (error) {
      this.handleError(getRequestError(error));
    } finally {
      versionLoadingStatus.pageLoading = false;
    }
  }

  @action.bound
  async saveDocument(
    document: DocumentModel,
    fields: WritableFields<DocumentModel>
  ) {
    try {
      const { data } = await this.api.updateDocument(document.uuid, fields);
      document.updateFromJson({
        title: data.data.title,
        updatedDate: data.data.updatedDate,
      });

      this.rootStore.messageStore.addMessage({
        type: "success",
        title: commonStoreTexts.updateComplete,
        text: commonStoreTexts.updatedWithTitle,
        values: {
          title: document.title,
        },
      });
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: commonStoreTexts.updatedFailedWithTitle,
        values: {
          title: document.title,
        },
        action: {
          title: commonTexts.retry,
          onClick: () => this.saveDocument(document, fields),
        },
      });
    }
  }

  async deleteDocument(document: DocumentModel) {
    this.clearDocument();

    try {
      await this.api.deleteDocument(document.uuid);
      this.removeDocument(document);

      PubSub.getInstance().notifyDelete({
        type: "Document",
        uuid: document.uuid,
        entryId: document.entryId,
        entryUuid: document.entryUuid,
      });

      this.rootStore.messageStore.addMessage({
        type: "success",
        title: texts.deleteDocumentSuccess,
      });
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.deleteDocumentFailed,
      });
    }
  }

  @action.bound
  async lockDocument(document: DocumentModel) {
    const expiryDate = addHours(new Date(), 24);

    try {
      const { data } = await this.api.createDocumentLock(
        document.uuid,
        expiryDate
      );

      document.updateFromJson({ lock: data.data });
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.lockDocumentFailed,
      });
    }
  }

  @action.bound
  async unlockDocument(document: DocumentModel) {
    const lockId = document.lock?.uuid;
    if (!lockId) {
      return;
    }

    try {
      await this.api.deleteLock(lockId);
      document.updateFromJson({ lock: undefined });
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.unlockDocumentFailed,
      });
    }
  }

  async startDocumentEdit(document: DocumentModel) {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await this.api.createDocumentEditSession(
        document.uuid,
        timezone
      );

      // Rely on data from polling to show the edit session information
      // because directly setting it on document caused it to flash on/off
      document.resetDataPollerFrequency();

      location.href = data.editUri;
    } catch (error) {
      this.rootStore.messageStore.addMessage({
        type: "failure",
        title: texts.editDocumentFailed,
      });
    }
  }

  /**
   * This polling mechanism is meant to be a poor man's version of the long term goal
   * of the Backend-for-Frontend pushing data updates to the end-users in realtime.
   *
   * Until we prioritise work on that, polling the backend for fresh `Document` data continously
   * while the end-user looks at an `Document`'s view, will provide much of the same value for
   * them.
   *
   * This mechanism is a perfect candidate to be extracted and made re-usable for other
   * parts we may want to poll data for. That will also make sense to increase testability.
   * Postponed that until we've proved this actually delivers on what our end-users need
   * and not write the wrong abstraction before we have more use cases.
   */
  pollForDocumentUpdates = async (document: DocumentModel) => {
    // polling requests fills up browser' concurrent request limit in extreme low bandwidth environments,
    // deliberately avoid polling to leave more room for other requests, especially having preview images in mind
    if (this.appConfig.hasFeature("lowBandwidth")) {
      return;
    }

    try {
      const { data } = await this.api.getPollableDocumentByUuid(document.uuid);
      const pollDocument = data.data;
      const versionHasChanged =
        pollDocument &&
        pollDocument.documentVersions[0] &&
        pollDocument.documentVersions[0].id !== document.versions[0].uuid;

      if (versionHasChanged) {
        document.updateState({ hasUpdate: "version" });
      }

      document.updateFromJson({
        lock: pollDocument?.lock,
        editSession: pollDocument?.editSession,
      });

      for (const version of document.versions) {
        const updatedJson = pollDocument.documentVersions.find(
          (x) => x.id === version.uuid
        );
        if (updatedJson) {
          version.updateFromJson(updatedJson);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        if (status && [403, 404].includes(status)) {
          document.stopDataPoller();
        }
      }
      console.error("Failed to poll for document changes", error);
    }
  };

  async pollForPreviewUpdates(
    document: DocumentModel
  ): Promise<DocumentPreviewState> {
    const version = document.currentVersion;

    try {
      const { data } = await this.api.getFilePreviewData(
        version.electronicDocumentId
      );

      version.updatePreview(data);

      return data.state;
    } catch (error) {
      console.error("Error while polling for document preview updates:", error);
      return "error";
    }
  }

  handleError(error: Error) {
    console.error("DocumentStore.handleError()", error);
    this.loading = false;

    this.rootStore.messageStore.addMessage({
      type: "failure",
      title: commonStoreTexts.somethingWentWrong,
      text: error.message,
    });
  }

  /** Subscriber interface */
  onDataAdded(event: AddEvent) {
    if (event.type === "DocumentVersion") {
      const doc = this.documentCache.get(event.data.parentUuid);
      doc?.addVersion(event.data);
    }
  }

  onDataDeleted(event: DeleteEvent) {
    if (event.type === "DocumentVersion") {
      const doc = this.documentCache.get(event.documentUuid);
      doc?.removeVersion(event.uuid);
    }
  }
}

const texts = defineMessages({
  deleteDocumentSuccess: {
    id: "document.message.deletedocument.success",
    defaultMessage: "Document deleted",
  },
  deleteDocumentFailed: {
    id: "document.message.deletedocument.failed",
    defaultMessage: "Could not delete document",
  },
  lockDocumentFailed: {
    id: "document.message.lock.failed",
    defaultMessage: "Could not lock document",
  },
  unlockDocumentFailed: {
    id: "document.message.unlock.failed",
    defaultMessage: "Could not unlock document",
  },
  editDocumentFailed: {
    id: "document.message.edit.failed",
    defaultMessage: "Could not edit document",
  },
});
