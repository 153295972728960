import React, { FC, ReactNode, useState } from "react";
import { DropEvent, FileRejection, FileWithPath } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { EntryUploadProps } from "@web/components/Upload/types";
import { UploadDropzone } from "@web/components/Upload/UploadDropzone";
import {
  RequiredTagsDialog,
  UploadChoice,
  UploadDialog,
} from "@web/components/Dialogs";
import { RequiredTagModel, SelectedTagsMap } from "@web/models";
import { handleEntryUpload } from "@web/components/Upload/handlers";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { usePubSub } from "@web/utils/hooks/usePubSub";

interface Props extends EntryUploadProps {
  isDisabled: boolean;
}

export const EntryUploadDropzone: FC<Props> = (p) => {
  const pubSub = usePubSub();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<ReactNode>();

  const handleDrop = async (
    files: File[],
    rejected: FileRejection[],
    event: DropEvent
  ) => {
    const tags = p.tags.copy();
    const isTagRequired = !tags.validate();
    const isMultipleFiles = groupFilePaths(files).length > 1;
    const isShiftKey = (event as DragEvent).shiftKey;

    if (isTagRequired) {
      const choice = await openTagRequiredDialog(tags);
      if (choice === "cancel") {
        closeDialog();
        return;
      }
      tags.applySelectedRequiredTag(choice);
      closeDialog();
    }

    let multipleEntries = false;
    if (isMultipleFiles && !isShiftKey) {
      const choice = await openMultipleFilesDialog();
      if (choice === "cancel") {
        closeDialog();
        return;
      }
      multipleEntries = choice === "multiple-entries";
      closeDialog();
    }

    await handleEntryUpload(
      files,
      {
        tags,
        sectionId: p.sectionId,
        onUpload: p.onUpload,
        getConfirmation: p.getConfirmation,
      },
      {
        multipleEntries,
      }
    );

    if (!multipleEntries) {
      const unsubscribe = pubSub.subscribe({
        onDataAdded: (event) => {
          if (event.type === "Document") {
            navigate(generateEntryUrl(event.data.entryId));
            unsubscribe();
          }
        },
      });
      setTimeout(unsubscribe, 3000);
    }
  };

  const closeDialog = () => setDialog(null);

  const openTagRequiredDialog = async (
    tags: SelectedTagsMap
  ): Promise<RequiredTagModel | "cancel"> =>
    new Promise((resolve) => {
      setDialog(
        <RequiredTagsDialog
          requiredTags={tags.requiredTags}
          onTagSelect={(choice) => resolve(choice)}
          onCancel={() => resolve("cancel")}
        />
      );
    });

  const openMultipleFilesDialog = async (): Promise<UploadChoice | "cancel"> =>
    new Promise((resolve) => {
      setDialog(
        <UploadDialog
          onConfirm={(choice) => resolve(choice)}
          onCancel={() => resolve("cancel")}
        />
      );
    });

  return (
    <UploadDropzone
      type="entry"
      multiple={true}
      disabled={p.isDisabled}
      overlayPosition={"fixed"}
      onDrop={handleDrop}
    >
      {p.children}
      {dialog}
    </UploadDropzone>
  );
};

/**
 * DropEvent.dataTransfer.items is behaving inconsistently across browsers, we'll use
 * this function to group the items instead. All files within a folders counts as one item.
 */
const groupFilePaths = (files: FileWithPath[]): string[] => {
  const items = files.reduce((acc: Set<string>, currentFile) => {
    let path = currentFile.path;
    if (path) {
      if (path.indexOf("/") > -1) {
        path = path.split("/")[1];
      }
      acc.add(path);
    }
    return acc;
  }, new Set([]));

  return Array.from(items);
};
