import { action, observable } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import PubSub from "./PubSub";
import { IDocumentVersionNode } from "@web/api/Integration/types";
import { RootStore } from ".";
import { DocumentVersionModel } from "@web/models";
import { API_URL } from "@web/utils/paths";
import { BffPaths } from "@web/api/BFF/types";

export class DocumentVersionStore {
  @observable
  private cache = new Map<UUID, DocumentVersionModel>();

  constructor(private api: Api, public rootStore: RootStore) {}

  /**
   * Return a DocumentVersionModel object from server data.
   */
  versionFromJson = (
    json: IDocumentVersionNode,
    parentId: number,
    parentUUID: UUID
  ): DocumentVersionModel => {
    const existing = this.cache.get(json.id);
    if (existing) {
      existing.updateFromJson(json);
      return existing;
    }

    const version = new DocumentVersionModel(this, json, parentId, parentUUID);
    this.cache.set(version.uuid, version);
    return version;
  };

  /**
   * Refresh preview data for a document version, if needed.
   * Tries up to 10 times if preview is still "in-progress".
   * Each retry will double the previous delay.
   */
  async loadPreview(
    version: DocumentVersionModel,
    retryCount = 1,
    retryDelay = 500
  ) {
    if (
      version.documentPreviewImage?.state === "complete" ||
      retryCount === 10
    ) {
      return;
    }

    const { data } = await this.api.getFilePreviewData(
      version.electronicDocumentId
    );
    version.documentPreviewImage = data;
    if (data.state === "in-progress") {
      setTimeout(() => {
        this.loadPreview(version, retryCount + 1, retryDelay * 2);
      }, retryDelay);
    }
  }

  async sendForSignature(version: DocumentVersionModel) {
    const documentId = version.parentUuid;
    const documentVersionId = version.uuid;
    const url = `${API_URL}${BffPaths.startScriveSignature}?documentId=${documentId}&documentVersionId=${documentVersionId}`;
    window.open(url, "_blank");
  }

  async deleteVersion(version: DocumentVersionModel) {
    const { messageStore } = this.rootStore;
    try {
      await this.api.deleteDocumentVersion(version.uuid);
      messageStore.addMessage({
        type: "success",
        title: texts.deleteVersionSuccess,
      });

      this.cache.delete(version.uuid);

      PubSub.getInstance().notifyDelete({
        type: "DocumentVersion",
        uuid: version.uuid,
        documentUuid: version.parentUuid,
      });
    } catch (error) {
      messageStore.addMessage({
        type: "failure",
        title: texts.deleteVersionFailed,
      });
    }
  }

  /**
   * The "OCR Check" in the BFF recreates the document preview if an OCR'ed PDF
   * has become available for the given version.
   */
  @action
  async runBffOcrCheckIfNeeded(version: DocumentVersionModel) {
    if (!version.needsOcrCheck) {
      return;
    }

    try {
      const { data } = await this.api.runBffOcrCheck(
        version.electronicDocumentId
      );
      version.updateFromJson({ documentPreviewImage: data });
    } catch (error) {
      console.warn("OCR check failed, ignoring result.", error);
    }

    version.updateState({ hasRunOcrCheck: true });

    this.loadPreview(version);
  }
}

const texts = defineMessages({
  deleteVersionFailed: {
    id: "document.message.deleteversion.failed",
    defaultMessage: "Could not delete version",
  },
  deleteVersionSuccess: {
    id: "document.message.deleteversion.success",
    defaultMessage: "Version deleted",
  },
});
