/**
 * These components are separated into a different file so that they don't create circular dependencies.
 * The error is not picked up by the TS compiler, but causes a runtime error. Not sure why.
 */

import styled, { css } from "styled-components";
import { vars } from "@web/styles";

export type ButtonVariantType = keyof typeof variants;

export const buttonReset = css`
  background: unset;
  border: unset;
  color: unset;
  cursor: pointer;
  font: unset;
  margin: 0;
  padding: 0;
`;

export const baseButton = styled.button<{
  variant?: ButtonVariantType;
  size?: number;
}>`
  ${buttonReset};

  border-radius: 0.25em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.5em;
  height: 2.25em;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;

  ${(p) => variants[p.variant ?? "default"]};

  :disabled {
    opacity: 0.5;
    cursor: default;
  }

  :focus,
  :active {
    visibility: visible !important;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const StyledButton = styled(baseButton)`
  font-size: calc(${(p) => p.size ?? 1} * 0.875rem);
  padding: 0 0.75em;

  svg {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
  }
`;

export const StyledIconButton = styled(baseButton)`
  border-radius: 0.25em;
  font-size: calc(${(p) => p.size ?? 1} * 1rem);
  padding: 0;
  width: 2.25em;
  height: 2.25em;

  svg {
    width: 1.5em;
    height: 1.5em;
  }
`;

export const variants = {
  default: css`
    background: ${vars.dark05};
    color: ${vars.dark75};

    :hover {
      background: ${vars.dark10};
    }

    :active {
      background: ${vars.dark15};
    }
  `,

  blank: css`
    background: none;
    color: inherit;
  `,

  primary: css`
    background: ${vars.primary};
    color: ${vars.primaryFg};

    :hover {
      background: ${vars.primaryLight10};
    }

    :active {
      background: ${vars.primaryDark10};
    }
  `,

  primaryInverted: css`
    background: transparent;
    color: ${vars.primary};

    :hover {
      background: ${vars.dark05};
    }

    :active {
      background: ${vars.dark15};
    }
  `,

  secondary: css`
    background: ${vars.secondary};
    color: ${vars.secondaryFg};

    :hover {
      background: ${vars.secondaryLight10};
    }

    :active {
      background: ${vars.secondaryDark10};
    }
  `,

  secondaryInverted: css`
    background: ${vars.secondaryFg};
    color: ${vars.secondary};

    :hover {
      background: ${vars.secondaryFg};
    }

    :active {
      background: ${vars.secondaryFg};
    }
  `,
};
