import axios from "axios";
import throttle from "lodash/throttle";
import { action, computed, observable, ObservableMap } from "mobx";
import { defineMessages } from "react-intl";
import { Api } from "@web/api";
import { errorMessage } from "@web/utils/helpers";
import {
  DRAFT_ENTRY_ID,
  EntryUploadRequest,
  UploadGroup,
  UploadJob,
  UploadJobStatus,
  UploadRequest,
  UploadRequestType,
} from "@web/models";
import { MessageWithValues } from "@web/translations";
import PubSub from "@web/stores/PubSub";
import { RootStore } from ".";
import { UploadResult } from "@web/components/Upload/types";

export class UploadStore {
  @observable
  uploads: UploadGroup[] = [];

  public get allowDuplication(): boolean {
    return this._allowDuplication;
  }

  private jobMap = new ObservableMap<string, UploadJob>();

  constructor(
    private api: Api,
    private rootStore: RootStore,
    private _allowDuplication: boolean
  ) {
    window.onbeforeunload = () => {
      if (this.hasActiveUploads) {
        return "Upload in progress. Are you sure want to leave this page?";
      }
    };
  }

  @computed
  get hasActiveUploads(): boolean {
    return this.uploads.some((group) => group.isActive);
  }

  @computed
  get activeUploadsCount(): number {
    return this.uploads.filter((group) => group.isActive).length;
  }

  @computed
  get uploadText(): MessageWithValues | undefined {
    if (this.uploads.length === 0) {
      return undefined;
    }

    const values = {
      activeUploadsCount: this.activeUploadsCount,
      allUploadsCount: this.uploads.length,
    };

    const message = this.hasActiveUploads
      ? texts.uploading
      : texts.uploadedComplete;

    return {
      ...message,
      values,
    };
  }

  @action
  addRequest = async (request: UploadRequest, haveToSelectTag?: boolean): Promise<UploadResult | undefined> => {
    if (haveToSelectTag) {
      this.rootStore.messageStore.addMissingMandatoryTagMessage();
      return;
    }

    const entryId =
      request.type === UploadRequestType.document ? request.entryId : undefined;

    if (entryId === DRAFT_ENTRY_ID) {
      throw new Error(
        "Creating documents based on draft entry object is not allowed."
      );
    }

    const jobs =
      request.type === UploadRequestType.version
        ? [new UploadJob(request, request.file)]
        : request.files.map((file) => new UploadJob(request, file));

    return this.addJobs(request, jobs);
  };

  @action
  cancelAllJobs = () => {
    const allJobs = Array.from(this.jobMap.values());
    const runningJobs = allJobs.filter((job) => {
      switch (job.status) {
        case UploadJobStatus.created:
        case UploadJobStatus.waitingForEntry:
        case UploadJobStatus.readyForUpload:
        case UploadJobStatus.uploading:
          return true;
      }
    });

    runningJobs.forEach((job) => job.cancel());
  };

  @action
  private addJobs = (
    request: UploadRequest,
    jobs: UploadJob[]
  ): Promise<UploadResult> => {
    jobs.forEach((job) => this.jobMap.set(job.jobId, job));

    if (request.type === UploadRequestType.entry) {
      this.uploads.unshift(new UploadGroup(jobs, request.type, request.title));
    } else {
      jobs.forEach((job) =>
        this.uploads.unshift(new UploadGroup([job], request.type))
      );
    }

    return this.runJobs(request, jobs);
  };

  private runJobs = async (
    request: UploadRequest,
    jobs: UploadJob[]
  ): Promise<UploadResult> => {
    if (jobs.some((job) => job.status !== UploadJobStatus.created)) {
      throw new Error("Can only run job from 'created' status");
    }

    for (const job of jobs) {
      job.setStatus(UploadJobStatus.readyForUpload);
      await this.runJob(job);
    }

    return {
      jobs,
      createEntities: async () => {
        await this.createEntities(request, jobs);
      },
    };
  };

  private async createEntities(request: UploadRequest, jobs: UploadJob[]) {
    const hasUploadingJobs = jobs.some(
      (job) => job.status === UploadJobStatus.uploading
    );

    // Do not create empty entry if all jobs are either cancelled or failed
    if (!hasUploadingJobs) {
      return;
    }

    // If we are executing an entry upload request we need to
    // create a new entry before we can proceed.
    if (request.type === UploadRequestType.entry) {
      const newEntry = await this.createEntry(request);
      jobs.forEach((job) => job.setResult(newEntry));
    }

    for await (const job of jobs) {
      if (job.status === UploadJobStatus.uploading) {
        if (job.requestType === UploadRequestType.version) {
          await this.createVersion(job);
        } else {
          await this.createDocument(job);
        }

        await this.completeJob(job);
      }
    }
  }

  private runJob = async (job: UploadJob) => {
    if (job.status !== UploadJobStatus.readyForUpload) {
      throw new Error(
        "Can only start uploading job from 'readyForUpload' status"
      );
    }
    job.setStatus(UploadJobStatus.uploading);
    try {
      await this.uploadFile(job);
    } catch (err) {
      if (axios.isCancel(err)) {
        job.setStatus(UploadJobStatus.cancelled);
      } else {
        job.setStatus(UploadJobStatus.failed);
        job.setResult({ error: errorMessage(err) });
      }
    }
  };

  private completeJob = async (job: UploadJob) => {
    if (job.status !== UploadJobStatus.uploading) {
      return;
    }
    job.setStatus(UploadJobStatus.completed);
  };

  private createEntry = async (request: EntryUploadRequest) => {
    const { sectionId, tags, title, isSingleDocumentEntry } = request;
    const entry = await this.rootStore.recordStore.createEntry({
      sectionId,
      tags,
      title,
      isSingleDocumentEntry,
    });

    return {
      entryId: entry.id,
      entryUuid: entry.uuid,
    };
  };

  private uploadFile = async (job: UploadJob) => {
    const { file } = job.data;
    const cancelToken = job.cancelToken.token;
    const result = await this.api.uploadFile(
      file,
      throttle(({ loaded, total }) => {
        job.setProgress({
          totalBytes: total,
          completedBytes: loaded,
          completedPercent: (80 * loaded) / total,
        });
      }, 100),
      cancelToken
    );

    const res = await this.api.hasDuplicatedChecksum(result.data.checksum);

    job.setResult({
      fileId: result.data.id,
      checkSum: result.data.checksum,
      isDuplicated: res.data.data.length > 0 || false,
    });
  };

  private createVersion = async (job: UploadJob) => {
    const { fileId, documentUuid, documentId } = job.result;

    if (!fileId) {
      throw new Error(
        "job.result.fileId is required before creating document version"
      );
    }

    if (!documentUuid || !documentId) {
      throw new Error(
        "job.result.documentUuid|id is required before creating document version"
      );
    }

    try {
      const { data } = await this.api.createDocumentVersion(
        fileId,
        documentUuid
      );

      const { documentVersionStore } = this.rootStore;
      const version = documentVersionStore.versionFromJson(
        data.data,
        documentId,
        documentUuid
      );

      PubSub.getInstance().notifyAdd({
        type: "DocumentVersion",
        data: version,
      });
    } catch (e) {
      throw new Error("Failed to create document version after upload " + e);
    }
  };

  private createDocument = async (job: UploadJob) => {
    const { title } = job.data;
    const { fileId, entryUuid } = job.result;
    const cancelToken = job.cancelToken.token;

    if (!fileId) {
      throw new Error("job.result.fileId is required before creating document");
    }

    if (!entryUuid) {
      throw new Error(
        "job.result.entryUuid is required before creating document"
      );
    }

    job.startProgressUpdate((time) => {
      const completedPercent = Math.min(100, 90 + 10 * (time / 20000));
      return { completedPercent };
    });

    try {
      const { data } = await this.api.createDocument(
        title,
        fileId,
        entryUuid,
        cancelToken
      );
      const document = this.rootStore.documentStore.documentFromJson(data.data);

      job.setResult({
        documentId: document.id,
        documentUuid: document.uuid,
      });

      PubSub.getInstance().notifyAdd({
        type: "Document",
        data: document,
      });
    } catch (e) {
      throw new Error("Failed to create document after upload " + e);
    }
  };
}

const texts = defineMessages({
  uploading: {
    id: "upload.overlay.title.uploading.web",
    defaultMessage: `Uploading {activeUploadsCount, plural,
      one {1 item}
      other {# items}
    }`,
  },
  uploadedComplete: {
    id: "upload.overlay.title.uploadingcomplete.web",
    defaultMessage: `Finished uploading`,
  },
});
