import React, { ForwardedRef, forwardRef } from "react";
import styled, { css } from "styled-components";
import { ellipsis } from "polished";
import isEmpty from "lodash/isEmpty";
import { useIntl } from "react-intl";
import { vars } from "@web/styles";
import { commonTexts } from "@web/translations";

interface Props {
  title?: string;
  values?: string[];
  overflowing?: boolean | string[];
  variant?: LabelVariant;
  disabled?: boolean;
  toggled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

export const Label = forwardRef((p: Props, ref: ForwardedRef<any>) => {
  const intl = useIntl();
  const values = p.values || [];
  const overflowing = p.overflowing || [];

  return (
    <_wrap
      ref={ref}
      as={p.onClick ? "button" : "div"}
      disabled={p.disabled}
      variant={p.variant}
      title={p.title}
      data-toggled={p.toggled || !isEmpty(values)}
      data-testid="Label"
      onClick={p.onClick}
    >
      {p.children || (
        <>
          {p.title && (
            <_title>
              {p.title}
              {!isEmpty(values) && ": "}
            </_title>
          )}

          {values.length > 0 && <_values>{values.join(", ")}</_values>}

          {overflowing === true ? (
            <_overflow>
              {" + "}
              {intl.formatMessage(commonTexts.more)}
            </_overflow>
          ) : (
            overflowing.length > 0 && (
              <_overflow title={overflowing.join(", ")}>
                {" +"}
                {overflowing.length}
              </_overflow>
            )
          )}
        </>
      )}
    </_wrap>
  );
});

type WrapProps = Pick<Props, "variant">;

const _wrap = styled.div<WrapProps>`
  border-radius: 2rem;
  display: inline-flex;
  gap: 0.25rem;
  flex-shrink: 0;
  font-size: 0.8125rem;
  font-family: inherit;
  margin: 0;
  padding: 0 0.625rem;
  height: 1.875rem;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 100%;

  :is(button):hover:not(:disabled) {
    cursor: pointer;
  }

  ${(p) => labelVariants[p.variant || "default"]};
`;

const _title = styled.span`
  flex-shrink: 0;
  white-space: nowrap;

  :not(:only-child) {
    font-weight: bold;
  }
`;

const _values = styled.span`
  ${ellipsis()};
`;

const _overflow = styled.span`
  font-size: 0.875em;
  border-radius: 2em;
  padding: 0.125em 0.5em;
  margin-right: -0.25rem;
`;

export type LabelVariant = keyof typeof labelVariants;

export const labelVariants = {
  default: css`
    background: ${vars.light95};
    border: 1px solid ${vars.secondaryAlt};
    color: ${vars.secondary};

    :is(button):hover:not(:disabled) {
      background: ${vars.secondaryAltLight30};
      border-color: ${vars.secondaryAltDark20};
      color: ${vars.secondaryAltFg};
    }

    :is([data-toggled="true"]) {
      background: ${vars.secondaryAltLight20};
      border-color: transparent;
      color: ${vars.secondary};

      :is(button):hover:not(:disabled) {
        background: ${vars.secondaryAltLight20};
        border-color: ${vars.secondaryAltDark20};
      }
    }

    ${_overflow} {
      background: ${vars.secondaryAlt};
      color: ${vars.secondaryAltFg};
    }
  `,

  outline: css`
    background: transparent;
    border: 1px solid currentColor;
    color: inherit;

    :is(button):hover:not(:disabled) {
      background: ${vars.light15};
    }

    :is([data-toggled="true"]) {
      background: ${vars.secondaryFg};
      border-color: transparent;
      color: ${vars.secondary};

      :is(button):hover:not(:disabled) {
        background: ${vars.secondaryFg};
      }
    }

    ${_overflow} {
      background: ${vars.secondaryAlt};
    }
  `,
};
