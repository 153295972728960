import flatMap from "lodash/flatMap";
import remove from "lodash/remove";
import groupBy from "lodash/groupBy";
import { differenceInHours, differenceInSeconds, isSameDay } from "date-fns";
import {
  ITagNode,
  IColorStatusNode,
  ChangelogEventNode,
  IColorStatusNodeExtended,
  ITagNodeExpanded,
  ILockNode,
  IDocumentNode,
  IntegrationApiPaths,
} from "@web/api/Integration/types";
import { InternalApiPaths } from "@web/api/Internal/types";
import {
  ResultHighlightPart,
  TagModel,
  ColorStatusModel,
  TagStatusColor,
  ChangelogEventModel,
  CollapsedEventsModel,
  SingleEventModel,
  AttributeModel,
  SelectedTagStatusMap,
  SelectedTagsModel,
  LockModel,
} from "@web/models";
import { API_URL } from "@web/utils/paths";
import { CommentModel } from "@web/models/CommentModel";

import {
  DocumentEditSession,
  DocumentEditSessionRaw,
} from "@web/api/BFF/types";
import { CommentStore } from "./CommentStore";
import { AttributeStore } from ".";

/** Tag */
export const parseTagResponse = (
  {
    data,
  }: {
    data: ITagNode[];
  },
  store: AttributeStore
): TagModel[] => {
  return data.map((t) => parseTagNode(t, store));
};

export const parseTagNode = (
  node: ITagNode,
  store: AttributeStore
): TagModel => {
  const hasAttributeValues =
    !!node.attributeValues && !!node.attributeListValues;
  return {
    permissions: new Set(node.effectivePermissions),
    title: node.title,
    id: node.internalIdentifier,
    uuid: node.id,
    attributes: (node.attributeDefinitions || []).map(
      (n) => new AttributeModel(store!, n)
    ),
    status: node.tagStatus ? parseColorStatusNode(node.tagStatus) : undefined,
    createdDate: node.createdDate,
    attributeValues: store.valuesForTag(
      node.id,
      hasAttributeValues
        ? [...(node.attributeValues ?? []), ...(node.attributeListValues ?? [])]
        : undefined
    ),
  };
};

export const parseAndGroupTagResponse = (
  data: ITagNodeExpanded[],
  store: AttributeStore
): SelectedTagsModel => {
  const tagMap = new Map<string, TagModel[]>();
  const groupedTags = groupBy(data, "classification.id");

  Object.entries(groupedTags).forEach(([classification, tags]) =>
    tagMap.set(
      classification,
      tags.map((tagNode) => parseTagNode(tagNode, store))
    )
  );
  return tagMap;
};

export const parseTagStatusResponse = ({
  data,
}: {
  data: IColorStatusNodeExtended[];
}): SelectedTagStatusMap => {
  const map: SelectedTagStatusMap = new Map();
  const grouped: Record<UUID, IColorStatusNodeExtended[]> = groupBy(
    data,
    "classification.id"
  );

  Object.entries(grouped).forEach(([uuid, statuses]) =>
    map.set(uuid, new Set(statuses.map((status) => status.internalIdentifier)))
  );

  return map;
};

/** Color status */
export const parseColorStatusNode = (
  node: IColorStatusNode
): ColorStatusModel => {
  let safeColor: keyof typeof TagStatusColor = "noColor";
  if (node.color && Object.keys(TagStatusColor).includes(node.color)) {
    safeColor = node.color as keyof typeof TagStatusColor;
  }

  return {
    uuid: node.id,
    id: node.internalIdentifier,
    name: node.name,
    color: safeColor,
    isArchived: node.isArchived,
  };
};

/** Lock */
export function parseLockNode(
  node: ILockNode,
  currentUserId?: UUID
): LockModel {
  return {
    uuid: node.id,
    expiryDate: new Date(node.expiryDate),
    createdBy: node.createdBy,
    createdByUserId: node.createdByUserId,
    createdByCurrentUser: currentUserId === node.createdByUserId,
    createdDate: new Date(node.createdDate),
    updatedBy: node.updatedBy,
    updatedByUserId: node.updatedByUserId,
    updatedDate: new Date(node.updatedDate),
    permissions: new Set(node.effectivePermissions),
  };
}

/** Document[version] */
export function parseDocumentEditSession(
  data: DocumentEditSessionRaw
): DocumentEditSession {
  return {
    id: data.id,
    lockId: data.lockId,
    documentId: data.documentId,
    createdBy: data.createdBy,
    createdAt: new Date(data.createdAt),
    lastSavedAt: data.lastSavedAt ? new Date(data.lastSavedAt) : undefined,
  };
}

export function parseDocumentSearchHighlights(node: IDocumentNode) {
  const documentHighlights = node.highlights;
  const versionHighlights = node.documentVersions[0]?.highlights;

  if (!node.highlights && !versionHighlights) {
    return undefined;
  }
  return {
    title: documentHighlights
      ? parseSearchHighlights(documentHighlights["title"])
      : [],
    fileContent: versionHighlights
      ? parseSearchHighlights(versionHighlights["text"])
      : [],
  };
}

export function parseEntrySearchHighlights(
  highlights: Record<string, string[]> | undefined
) {
  if (!highlights || Object.keys(highlights).length === 0) {
    return {
      title: [],
    };
  }
  return {
    title: parseSearchHighlights(highlights["title"]),
  };
}

export function parseSearchHighlights(
  texts: string[] | undefined
): ResultHighlightPart[] {
  let highlightIdCounter = 0;
  const text = (texts && texts.join(". ")) || "";
  const parts = flatMap(text.split("|=hlstart=|"), (partText) => {
    const split = partText.split("|=hlstop=|");
    const subParts: ResultHighlightPart[] = [
      {
        id: `h${highlightIdCounter++}`,
        isHighlighted: split.length > 1,
        text: split[0],
      },
    ];
    if (split.length === 2) {
      subParts.push({
        id: `h${highlightIdCounter++}`,
        isHighlighted: false,
        text: split[1],
      });
    }
    return subParts;
  });
  if (parts.length === 1 && parts[0].text === "") {
    return [];
  }
  if (parts.length > 1 && !parts[0].isHighlighted) {
    let firstText = parts[0].text;
    firstText = firstText.split(" ").slice(-3).join(" ");
    parts[0].text = firstText;
  }
  return parts;
}

export function generateDownloadEntryDocumentsUrl(entryId: UUID) {
  return (
    API_URL + InternalApiPaths.entryDocumentsDownload.replace("{uuid}", entryId)
  );
}

export function generateDownloadDocumentVersionUrl(uuid: UUID | undefined) {
  return uuid ? `${API_URL}${IntegrationApiPaths.downloadFile}/${uuid}` : "";
}

function isSystemComment(event: SingleEventModel) {
  return event.userId === "System";
}

/** Changelog */
export function parseChangelogResponse(
  {
    data,
  }: {
    data: ChangelogEventNode[];
  },
  // TODO: can be removed when API responds with permissions information
  commentStore: CommentStore
): ChangelogEventModel[] {
  const deletedComments: Set<string> = new Set();

  let previousEvent: SingleEventModel | undefined;
  let currentCollapseGroup: CollapsedEventsModel | undefined;
  const results: ChangelogEventModel[] = [];
  const events = data.map((eventNode) =>
    parseChangelogEventNode(eventNode, commentStore)
  );
  for (const event of events) {
    const { eventType, type, userName } = event;
    if (
      event.type === "Comment" &&
      event.eventType === "DEL" &&
      !isSystemComment(event)
    ) {
      /* Deleted comments are not displayed. We keep a record of them to not
          display the comment when it was added. - ED may 2021 */
      deletedComments.add(event.systemId);
      continue;
    }
    const isEventTypeCollapsible =
      ["ADD", "DEL"].includes(eventType) && "Document" === type;
    const isSimilarToPrevious =
      type === previousEvent?.type &&
      eventType === previousEvent?.eventType &&
      userName === previousEvent?.userName;

    const collapseStartTime =
      currentCollapseGroup?.newestEventTime || previousEvent?.eventTime;

    const isWithinOneHourFromCollapsingStarted =
      collapseStartTime !== undefined
        ? isSameDay(collapseStartTime, event.eventTime) &&
          differenceInHours(collapseStartTime, event.eventTime) === 0
        : false;
    if (
      previousEvent &&
      previousEvent.type === "Comment" &&
      !isSystemComment(previousEvent) &&
      event.eventType !== "DEL" &&
      !deletedComments.has(previousEvent.systemId)
    ) {
      const differenceToPreviousEvent: number = differenceInSeconds(
        previousEvent.eventTime,
        event.eventTime
      );
      if (differenceToPreviousEvent <= 2) {
        /* Comments are related to the previous event if they are posted close to each other.
        The comment event get set on the event as "relatedComment".
        This is a work around as the backend does not have any concept of related events or to 
        comment on changes made. - ED, May 2021
        */
        event.relatedComment = previousEvent;
        remove(results, (event) => event === previousEvent);
        results.push(event);
        currentCollapseGroup = undefined;
      } else {
        // These are comments unrelated to an event and not posted by the system.
        results.push(event);
      }
    } else if (
      previousEvent &&
      isSimilarToPrevious &&
      isEventTypeCollapsible &&
      isWithinOneHourFromCollapsingStarted
    ) {
      const collapseGroup: CollapsedEventsModel = currentCollapseGroup ?? {
        eventType,
        type,
        isCollapsed: true,
        id: previousEvent.id,
        events: [previousEvent],
        newestEventTime: previousEvent.eventTime,
        oldestEventTime: event.eventTime,
        userName: event.userName,
        userId: event.userId,
      };

      collapseGroup.events.push(event);
      collapseGroup.id = `${collapseGroup.id}-${event.id}`;
      collapseGroup.oldestEventTime = event.eventTime;

      if (currentCollapseGroup === undefined) {
        results.push(collapseGroup);

        // To avoid duplicate events, remove the previous event that was added as a single event
        // from the results because it is now included in a collapsed group of events
        remove(results, (event) => event === previousEvent);
      }

      currentCollapseGroup = collapseGroup;
    } else if (!deletedComments.has(event.systemId)) {
      currentCollapseGroup = undefined;
      results.push(event);
    }
    previousEvent = event;
  }
  return results;
}

export function parseChangelogEventNode(
  event: ChangelogEventNode,
  commentStore: CommentStore
): SingleEventModel {
  const model: SingleEventModel = {
    id: `${event.transactionId}-${event.systemId}`,
    eventTime: new Date(event.eventTime),
    eventType: event.eventType,
    changed: event.changed ?? {},
    systemId: event.systemId,
    type: event.type,
    fields: event.fields,
    userId: event.userId,
    userName: event.userName,
    isCollapsed: false,
  };

  if (model.type === "Comment") {
    model.comment = new CommentModel(
      commentStore,
      model.systemId,
      model.userId
    );
  }

  return model;
}
