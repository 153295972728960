import { action, observable } from "mobx";
import { IConfig } from "@web/api/BFF/types";
import { SimpleTheme } from "@web/styles/themes";
import {
  getThemeFromUrl,
  getLocaleFromStorage,
  storeLocale,
  getFeaturesFromUrl,
} from "@web/utils/helpers";
import { SupportedLocale } from "@web/translations";
import { FeatureToggles } from "./types";

export class AppConfig {
  @observable instance: string;
  @observable locale: SupportedLocale;
  @observable theme?: SimpleTheme;

  private readonly featureToggles: FeatureToggles;

  constructor(
    private readonly json: IConfig,
    private overrideFeatureToggles: Partial<FeatureToggles> = getFeaturesFromUrl(),
    private overrideTheme: SimpleTheme | undefined = getThemeFromUrl()
  ) {
    this.instance = json.instance || "Documaster";
    this.locale = getLocaleFromStorage() || json.locale || SupportedLocale.NB;
    this.setSimpleTheme(overrideTheme ?? json.theme);

    this.featureToggles = {
      navBar: true,
      sectionSelect: true,
      search: true,
      themePicker: false,
      lowBandwidth: false,
      ...overrideFeatureToggles,
    };
  }

  /**
   * Config from the Backend/BFF.
   */
  get canUploadDocuments() {
    return this.json.servicePermissions.includes("UploadDocuments");
  }

  get allowDuplication() {
    return this.json.allowDuplication;
  }

  get canCreateSection() {
    return (
      this.json.globalPermissions.includes("CreateChildren") &&
      this.json.globalPermissions.includes("UpdateChildren")
    );
  }

  get canCreateClassification() {
    return this.canCreateSection;
  }

  get canManageFlows() {
    return this.json.servicePermissions.includes("ManageFlows");
  }

  get canManageAccessGroups() {
    return this.json.servicePermissions.includes("ManageAccessGroups");
  }

  get canChangeEntryStatus() {
    return this.json.servicePermissions.includes("ChangeEntryStatus");
  }

  get canSendForSignature() {
    return this.json.features?.esignIntegration ?? false;
  }

  get loggedInUser() {
    return {
      id: this.json.userId,
      name: this.json.userName,
    };
  }

  hasFeature = (feature: keyof FeatureToggles) => this.featureToggles[feature];

  @action.bound
  setLocale(locale: SupportedLocale) {
    this.locale = locale;
    storeLocale(locale);
  }

  setSimpleTheme = (theme?: SimpleTheme) => {
    this.theme = theme;
  };
}
