import React, { SyntheticEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Overlay } from "@web/components";
import { ActionMenu, Checkbox, Input } from "@web/elements";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import { AccessGroupModel } from "@web/models/AccessControlModel";
import { ClaimsMode, IAccessGroupNode } from "@web/api/Integration/types";
import { Button, MoreButton } from "@web/elements/Button";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { Dropdown } from "../Dropdown";
import { Menu, MenuItem } from "../Menu";

interface IProps {
  isCreating: boolean;
  accessGroup?: AccessGroupModel;
  onDone?: () => void;
  onCreate?: (
    json: Pick<
      IAccessGroupNode,
      "name" | "description" | "claims" | "claimsMode"
    >
  ) => void;
  onCancel: () => void;
}

export const EditAccessGroupBox = ({
  isCreating,
  accessGroup,
  onDone,
  onCreate,
  onCancel,
}: IProps) => {
  if (!isCreating && !accessGroup) {
    throw new Error(
      "EditAccessGroupBox needs 'accessGroup' prop if isCreating === false"
    );
  }

  const intl = useIntl();

  const [name, setName] = useState(accessGroup?.name ?? "");
  const [claimsMode, setClaimsMode] = useState(
    accessGroup?.claimsMode ?? ClaimsMode.ANY
  );
  const [claims, setClaims] = useState(accessGroup?.claims ?? []);
  const [description, setDescription] = useState(
    accessGroup?.description ?? ""
  );
  const claimsSig = [...claims].sort().join("|");

  const canSave = useCallback(
    () =>
      name.length > 0 &&
      claims.filter((c) => c.trim() !== "").length > 0 &&
      description.length > 0,
    [name, claimsSig, description]
  );

  const handleSave = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (isCreating && onCreate) {
        onCreate({ name, claims, description, claimsMode });
      } else if (!isCreating && accessGroup) {
        accessGroup.updateFields({
          name,
          claims,
          description,
          claimsMode,
        });
        onDone?.();
      }
    },
    [accessGroup?.uuid, name, claimsSig, description, claimsMode]
  );

  const setClaimAt = (index: number, claim: string) => {
    claims[index] = claim;

    setClaims([...claims]);
  };

  const addClaimBox = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setClaimAt(claims.length, "");
  };

  const toggleClaimsMode = () => {
    setClaimsMode(
      claimsMode === ClaimsMode.ANY ? ClaimsMode.ALL : ClaimsMode.ANY
    );
  };

  const [getConfirmation] = useConfirmationDialog();

  const handleDelete = useCallback(async () => {
    if (!accessGroup) {
      return;
    }

    const confirmed = await getConfirmation({
      title: texts.deleteGroupTitle,
      message: texts.deleteGroupQuestion,
      confirmText: commonTexts.delete,
      values: {
        name: accessGroup.name,
      },
    });

    if (confirmed) {
      accessGroup.delete();
      onDone?.();
    }
  }, [accessGroup?.uuid]);

  return (
    <Overlay>
      <_wrap>
        <_title>
          {isCreating ? (
            <FormattedMessage {...texts.createTitle} />
          ) : (
            <FormattedMessage {...texts.editTitle} />
          )}
        </_title>

        {!isCreating && (
          <_menu>
            <Dropdown toggle={(ref) => <MoreButton ref={ref} />}>
              <Menu>
                <MenuItem onClick={handleDelete}>
                  <FormattedMessage {...texts.deleteGroup} />
                </MenuItem>
              </Menu>
            </Dropdown>
          </_menu>
        )}

        <_rows>
          <_row>
            <h4>
              <FormattedMessage {...commonTexts.nameRequired} />:
            </h4>
            <Input
              autoFocus={true}
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </_row>
          {claims.map((claim, index) => (
            <_row key={`claim-${index}`}>
              <h4>
                <FormattedMessage {...texts.claimField} />:
              </h4>
              <Input
                value={claim}
                onChange={({ target }) => setClaimAt(index, target.value)}
              />
            </_row>
          ))}
          <_row $halfRightJustified={true}>
            <Checkbox
              label={intl.formatMessage(texts.requireAll)}
              checked={claimsMode === ClaimsMode.ALL}
              onChange={toggleClaimsMode}
            />
            <Button
              text={texts.addClaim}
              variant="primary"
              className="add-claim"
              onClick={addClaimBox}
            />
          </_row>
          <_row>
            <h4>
              <FormattedMessage {...texts.descriptionField} />:
            </h4>
            <Input
              value={description}
              onChange={({ target }) => setDescription(target.value)}
            />
          </_row>
        </_rows>

        <ActionMenu
          onApply={handleSave}
          onCancel={onCancel}
          applyText={commonTexts.save}
          applyIsDisabled={!canSave()}
          direction="horizontal"
        />
      </_wrap>
    </Overlay>
  );
};

const _wrap = styled.form`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 400px;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  position: relative;
`;

const _title = styled.h3`
  padding: 0;
  margin: 0 0 25px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const _menu = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
`;

const _rows = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const _row = styled.div<{ $halfRightJustified?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => props.$halfRightJustified && "align-items: end"};
  margin-bottom: 1rem;
  .add-claim {
    margin-top: 1rem;
  }
  > h4 {
    font-size: 14px;
    font-weight: inherit;
    margin: 0 0 0.5rem 0;
  }
  input {
    font-size: 14px;
  }
`;

const texts = defineMessages({
  addClaim: {
    id: "accessControl.editGroup.addClaim",
    defaultMessage: "Add claim",
  },
  requireAll: {
    id: "accessControl.editGroup.requireAll",
    defaultMessage: "Require all",
  },
  createTitle: {
    id: "accessControl.editGroup.createTitle",
    defaultMessage: "Create access group",
  },
  editTitle: {
    id: "accessControl.editGroup.editTitle",
    defaultMessage: "Edit access group",
  },
  claimField: {
    id: "accessControl.editGroup.claimField",
    defaultMessage: "Claim (required)",
  },
  descriptionField: {
    id: "accessControl.editGroup.descriptionField",
    defaultMessage: "Description (required)",
  },
  deleteGroup: {
    id: "accessControl.editGroup.deleteGroup",
    defaultMessage: "Delete access group",
  },
  deleteGroupTitle: {
    id: "accessControl.editGroup.deleteGroupDialog.title",
    defaultMessage: "Delete access group",
  },
  deleteGroupQuestion: {
    id: "accessControl.editGroup.deleteGroupDialog.question",
    defaultMessage:
      "This will delete the access group <em>{name}</em>. Are you sure you want to proceed?",
  },
});
