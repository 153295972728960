import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Dialog } from "@web/components/Dialog";
import { InfoText } from "@web/elements";
import { Button } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { RequiredTagModel } from "@web/models";
import { Label } from "@web/elements/Label";

interface IProps {
  requiredTags: RequiredTagModel[];
  onCancel: () => void;
  onTagSelect: (tag: RequiredTagModel) => void;
}

export const RequiredTagsDialog: FC<IProps> = observer((p) => {
  const intl = useIntl();

  return (
    <Dialog title={texts.title}>
      {intl.formatMessage(texts.message)}
      <_content>
        <_tags>
          {p.requiredTags.map((tag) => (
            <Label
              key={tag.uuid}
              title={tag.classification.title}
              values={[tag.title]}
              onClick={() => p.onTagSelect(tag)}
            />
          ))}
        </_tags>
        <InfoText>{intl.formatMessage(texts.hint)}</InfoText>

        <Button
          text={commonTexts.cancel}
          variant="primary"
          onClick={p.onCancel}
        />
      </_content>
    </Dialog>
  );
});

const _content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
  max-height: 300px;
  > * {
    margin-top: 8px;
  }

  button {
    width: 100%;
    margin-top: 30px;
  }
`;

const _tags = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin: 10px 0px;
  > * {
    margin: 4px 0px;
  }
`;

const texts = defineMessages({
  title: {
    id: "tag.required.dialog.title",
    defaultMessage: "One of these tags are required",
  },
  message: {
    id: "tag.required.dialog.message",
    defaultMessage: "Please choose which tag you want to use.",
  },
  hint: {
    id: "tag.required.dialog.hint",
    defaultMessage:
      "Tip: Any tag currently selected in the filters will be automatically applied when creating folders.",
  },
});
