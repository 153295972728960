import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import isEqual from "lodash/isEqual";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Overlay } from "@web/components";
import { ActionMenu, RadioButton } from "@web/elements";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import {
  findMatchingPermissionSet,
  NamedPermissionSet,
  permissionSetNames,
  PermissionType,
} from "@web/models/AccessControlModel";
import { PermissionToggles } from "./PermissionToggle";

interface IProps<T extends PermissionType> {
  accessGroupName?: string;
  initialPermissions: Set<T>;
  availablePermissions: T[];
  namedPermissionSets: NamedPermissionSet<T>[];
  onDone: (selected: Set<T>) => void;
  onCancel: () => void;
}

export const SelectPermissionsBox = observer(
  <T extends PermissionType>({
    accessGroupName,
    initialPermissions,
    availablePermissions,
    namedPermissionSets,
    onDone,
    onCancel,
  }: IProps<T>) => {
    const intl = useIntl();

    const isInitialCustomSelected =
      findMatchingPermissionSet(initialPermissions, namedPermissionSets) ===
      undefined;
    const [isCustomSelected, setCustomSelected] = useState(
      isInitialCustomSelected
    );
    const [selectedPermissions, setSelectedPermissions] =
      useState(initialPermissions);

    const handleSave = useCallback(() => {
      onDone(selectedPermissions);
    }, [onDone, selectedPermissions]);

    const handleCustomPermissionToggle = useCallback((permissions: Set<T>) => {
      setCustomSelected(true);
      setSelectedPermissions(permissions);
    }, []);

    const handleOptionSelected = useCallback(
      (name: string) => {
        const option = namedPermissionSets.find(
          (x) => name === intl.formatMessage(x.name)
        );
        const isCustomSelected =
          name === intl.formatMessage(permissionSetNames.custom);
        if (option) {
          setCustomSelected(false);
          setSelectedPermissions(option.permissions);
        } else if (isCustomSelected) {
          setCustomSelected(true);
          setSelectedPermissions(new Set());
        }
      },
      [namedPermissionSets]
    );

    const selectedSet = isCustomSelected
      ? undefined
      : findMatchingPermissionSet(selectedPermissions, namedPermissionSets);
    const hasChanged = !isEqual(initialPermissions, selectedPermissions);

    const showCustomOption = availablePermissions.length > 1;

    return (
      <Overlay>
        <_wrap>
          <_title>
            {accessGroupName ? (
              <FormattedMessage
                {...texts.titleForGroup}
                values={{ group: accessGroupName }}
              />
            ) : (
              <FormattedMessage {...texts.title} />
            )}
          </_title>
          <_options>
            {namedPermissionSets.map((set, index) => (
              <_option key={index}>
                <RadioButton
                  name="selected"
                  label={set.name}
                  value={intl.formatMessage(set.name)}
                  checked={selectedSet === set}
                  onChange={handleOptionSelected}
                />
                <PermissionToggles
                  availablePermissions={availablePermissions}
                  selectedPermissions={set.permissions}
                  isDisabled={true}
                />
              </_option>
            ))}

            {showCustomOption && (
              <_option key="Custom">
                <RadioButton
                  name="selected"
                  label={permissionSetNames.custom}
                  value={intl.formatMessage(permissionSetNames.custom)}
                  checked={isCustomSelected}
                  onChange={handleOptionSelected}
                />
                <PermissionToggles
                  availablePermissions={availablePermissions}
                  selectedPermissions={
                    isCustomSelected ? selectedPermissions : new Set<T>()
                  }
                  isDisabled={false}
                  onChange={handleCustomPermissionToggle}
                />
              </_option>
            )}
          </_options>

          <ActionMenu
            onApply={() => handleSave()}
            onCancel={onCancel}
            applyText={commonTexts.ok}
            applyIsDisabled={!hasChanged}
            direction="horizontal"
          />
        </_wrap>
      </Overlay>
    );
  }
);

const _wrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: auto;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
`;

const _title = styled.h3`
  padding: 0;
  margin: 0 0 25px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const _option = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
`;

const _options = styled.div`
  margin-bottom: 25px;
`;

const texts = defineMessages({
  title: {
    id: "accessControl.selectPermissions.title",
    defaultMessage: "Select permissions",
  },
  titleForGroup: {
    id: "accessControl.selectPermissions.titleForGroup",
    defaultMessage: "Select permissions for {group}",
  },
});
