import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, defineMessages } from "react-intl";
import { PipelineModel } from "@web/models";
import {
  IConditionNode,
  ISectionNode,
  ITagNodeExpanded,
} from "@web/api/Integration/types";
import { InfoText } from "@web/elements";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { vars } from "@web/styles";
import { CreateButton } from "../CreateButton";
import { RemoveButton } from "../RemoveButton";
import { AddCondition } from "./AddCondition";

interface IProps {
  pipeline: PipelineModel;
  findTags: (query: string) => Promise<ITagNodeExpanded[]>;
  findSections: (query: string) => Promise<ISectionNode[]>;
}

export const ConditionList: FC<IProps> = observer((p) => {
  const [getConfirmation] = useConfirmationDialog();
  const [addCondition, setAddCondition] = useState(false);

  const { conditions } = p.pipeline;

  const handleDelete = async (condition: IConditionNode) => {
    const confirmed = await getConfirmation({
      title: texts.deleteConfirmationTitle,
      message: texts.deleteConfirmationMessage,
    });

    if (confirmed) {
      p.pipeline.deleteCondition(condition.id);
    }
  };

  return (
    <_wrap>
      <InfoText>
        <FormattedMessage
          {...texts.infoText}
          values={{ br: <br />, b: (chunk: string) => <b>{chunk}</b> }}
        />
      </InfoText>

      {conditions.map((cond) => (
        <_condition key={cond.id}>
          {cond.name}
          <RemoveButton onClick={() => handleDelete(cond)} />
        </_condition>
      ))}

      {addCondition ? (
        <AddCondition
          pipeline={p.pipeline}
          findTags={p.findTags}
          findSections={p.findSections}
          onClose={() => setAddCondition(false)}
        />
      ) : (
        <_add>
          {conditions.length === 0 && (
            <_empty>
              <FormattedMessage {...texts.noConditionAdded} />
            </_empty>
          )}
          <CreateButton onClick={() => setAddCondition(true)} />
        </_add>
      )}
    </_wrap>
  );
});

const _wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const _condition = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px;
`;

const _add = styled.div`
  display: inline-flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  button {
    margin-left: auto;
  }
`;

const _empty = styled.div`
  color: ${vars.dark55};
`;

const texts = defineMessages({
  deleteConfirmationTitle: {
    id: "flow.settings.condition.delete.title",
    defaultMessage: "Are you sure you want to do this?",
  },
  deleteConfirmationMessage: {
    id: "flow.settings.condition.delete.message",
    defaultMessage: "This will permanently delete the condition.",
  },
  infoText: {
    id: "flow.settings.condition.info.text",
    defaultMessage:
      "Conditions determine which folders should be validated by this checklist.{br}<strong>Note</strong>: If no conditions are set, the checklist will validate <b>all</b> folders.",
  },
  noConditionAdded: {
    id: "flow.settings.condition.not.added",
    defaultMessage: "No conditions added for this checklist",
  },
});
