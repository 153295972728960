import React from "react";
import { UploadButton } from "./UploadButton";
import { observer } from "mobx-react";
import { DocumentUploadProps } from "./types";
import { handleDocumentUpload } from "./handlers";
import { AddIcon } from "@web/elements/Icons";
import { StyledIconButton } from "@web/elements/Button/styles";

export const DocumentUploadButton: React.FC<DocumentUploadProps> = observer(
  (p) => {
    const handleUpload = (files: File[]) => {
      handleDocumentUpload(files, p);
    };

    const { children } = p;
    return (
      <UploadButton multiple={true} onUpload={handleUpload}>
        {children ? (
          children
        ) : (
          <StyledIconButton as="div">
            <AddIcon />
          </StyledIconButton>
        )}
      </UploadButton>
    );
  }
);
