import React, { ReactNode, useRef, useState } from "react";
import { observer } from "mobx-react";
import { DropzoneRef } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { useStores } from "@web/stores/context";
import { UploadOverlay } from "@web/components";
import { RequiredTagModel, SelectedTagsMap, UploadRequest } from "@web/models";
import { FloatingButton } from "@web/elements/FloatingButton";
import { AddIcon, FileUploadIcon, FolderCreateIcon } from "@web/elements/Icons";
import { RequiredTagsDialog } from "@web/components/Dialogs/RequiredTagsDialog";
import { generateEntryUrl } from "@web/utils/URLHelpers";
import { Dropdown } from "@web/components/Dropdown";
import { Menu, MenuItem } from "@web/components/Menu";
import { handleEntryUpload } from "@web/components/Upload/handlers";
import { UploadInput } from "@web/components/Upload/UploadInput";
import { usePubSub } from "@web/utils/hooks/usePubSub";
import { useConfig } from "@config/context";
import { useDuplicateUploadConfirmDialog } from "@web/utils/hooks/useDuplicateUploadConfirmDialog";

const UploadContainer: React.FC = observer(() => {
  const config = useConfig();
  const {
    documentStore,
    filterStore,
    recordStore,
    resultStore,
    sectionStore,
    uploadStore,
    messageStore,
  } = useStores();
  const intl = useIntl();
  const pubSub = usePubSub();
  const navigate = useNavigate();
  const uploadInputRef = useRef<DropzoneRef>(null);
  const [dialog, setDialog] = useState<ReactNode>();

  const getConfirmation = useDuplicateUploadConfirmDialog();

  const handleEntryClick = async (entryId: number) => {
    navigate(generateEntryUrl(entryId));
    await recordStore.loadEntry(entryId);
    resultStore.focus(entryId);
  };

  const handleDocumentClick = async (documentId: number, entryId: number) => {
    navigate(generateEntryUrl(entryId));
    await recordStore.loadEntry(entryId);
  };

  const handleCancelClick = () => {
    uploadStore.cancelAllJobs();
  };

  const handleFileUploadClick = () => {
    if (filterStore.haveToSelectFromMandatoryClass) {
      messageStore.addMissingMandatoryTagMessage();
      return;
    }

    uploadInputRef.current?.open();
  };

  const handleNewEntryClick = async () => {
    if (filterStore.haveToSelectFromMandatoryClass) {
      messageStore.addMissingMandatoryTagMessage();
      return;
    }

    const tags = filterStore.selectedTags.copy();

    const sectionUuid = sectionStore.selectedSection?.uuid;
    const sectionId = sectionStore.selectedSection?.id;
    if (!sectionId || !sectionUuid) {
      return;
    }

    if (!tags.validate()) {
      const choice = await openTagRequiredDialog(tags);
      if (choice === "cancel") {
        recordStore.clearEntry();
        closeDialog();
        return;
      }
      tags.applySelectedRequiredTag(choice);
      closeDialog();
    }

    recordStore.startDraftEntry({
      tags,
      sectionUuid,
      sectionId,
    });
  };

  const handleUploadFiles = async (files: File[]) => {
    const tags = filterStore.selectedTags.copy();

    if (!tags.validate()) {
      const choice = await openTagRequiredDialog(tags);
      if (choice === "cancel") return closeDialog();
      tags.selectedRequiredTag = choice;
      closeDialog();
    }

    const onUpload = async (request: UploadRequest) => {
      return uploadStore.addRequest(
        request,
        filterStore.haveToSelectFromMandatoryClass
      );
    };

    handleEntryUpload(
      files,
      {
        tags,
        sectionId: sectionStore.selectedSection?.uuid,
        onUpload,
        getConfirmation,
      },
      {
        multipleEntries: true,
      }
    );

    if (files.length === 1) {
      const unsubscribe = pubSub.subscribe({
        onDataAdded: (event) => {
          if (event.type === "Document") {
            navigate(generateEntryUrl(event.data.entryId));
            unsubscribe();
          }
        },
      });
      setTimeout(unsubscribe, 3000);
    }
  };

  const closeDialog = () => setDialog(null);

  const openTagRequiredDialog = async (
    tags: SelectedTagsMap
  ): Promise<RequiredTagModel | "cancel"> =>
    new Promise((resolve) => {
      setDialog(
        <RequiredTagsDialog
          requiredTags={tags.requiredTags}
          onTagSelect={(choice) => resolve(choice)}
          onCancel={() => resolve("cancel")}
        />
      );
    });

  return (
    <>
      {/**
       NOTE: This check needs to be located here (as opposed to an early return),
       or the uploadInputRef won't work until the component re-renders.
       I don't have the slightest idea why...
       */}
      {sectionStore.canWriteInSelectedSection && config.canUploadDocuments && (
        <Dropdown
          variation="light"
          toggle={(ref) => (
            <FloatingButton ref={ref}>
              <AddIcon />
            </FloatingButton>
          )}
        >
          <Menu>
            <MenuItem onClick={handleNewEntryClick}>
              <FolderCreateIcon />
              {intl.formatMessage(texts.newEntry)}
            </MenuItem>

            <MenuItem onClick={handleFileUploadClick}>
              <FileUploadIcon />
              {intl.formatMessage(texts.uploadFiles)}
            </MenuItem>
          </Menu>
        </Dropdown>
      )}

      <UploadInput
        ref={uploadInputRef}
        type="multipleFiles"
        onUpload={handleUploadFiles}
      />

      <UploadOverlay
        uploads={uploadStore.uploads}
        uploadText={uploadStore.uploadText}
        hasActiveUploads={uploadStore.hasActiveUploads}
        onDocumentClick={handleDocumentClick}
        onRecordClick={handleEntryClick}
        onCancelClick={handleCancelClick}
        displayedDocumentId={documentStore.document?.id}
        displayedEntryId={recordStore.entry?.id}
      />

      {dialog}
    </>
  );
});

const texts = defineMessages({
  newEntry: {
    id: "upload.dropdown.newEntry",
    defaultMessage: "New folder",
  },
  uploadFiles: {
    id: "upload.dropdown.uploadFiles",
    defaultMessage: "Upload files",
  },
});

export default UploadContainer;
