import React, { FC } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { DocumentBucket, EntryModel, SectionModel } from "@web/models";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { Button, CloseButton } from "@web/elements/Button";
import { commonTexts } from "@web/translations";
import { MoveIcon } from "@web/elements/Icons";
import { useIsSearchPage } from "@web/pages/SearchPage/handler";
import { Dropdown } from "../Dropdown";
import { Menu, MenuItem } from "../Menu";
import * as Bucket from "./styles";
import { texts } from "./texts";

interface Props {
  bucket: DocumentBucket;
  openEntry?: EntryModel;
  openSection?: SectionModel;
  onClose: () => void;
}

export const DocumentBucketOverlay: FC<Props> = observer((p) => {
  const intl = useIntl();
  const [getConfirmation] = useConfirmationDialog();
  const isSearchPage = useIsSearchPage();

  const hasChangedEntry =
    p.openEntry && p.bucket.entryUuid !== p.openEntry.uuid;

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: texts.dialog_delete_title,
      message: texts.dialog_delete_document_message,
      confirmText: commonTexts.delete,
      values: {
        count: p.bucket.itemCount,
      },
    });

    if (confirmed) {
      p.bucket.deleteSelection();
    }
  };

  const handleMoveToFolder = () => {
    p.bucket.setAction("Move");
    p.bucket.specifyMoveAction("MoveToFolder");
  };

  const handleMoveToSection = async () => {
    const confirmed = await getConfirmation({
      title: texts.move_documents_dialog_confirm_title,
      message: texts.move_documents_dialog_confirm_message,
    });

    if (confirmed) {
      p.bucket.setAction("Move");
      p.bucket.specifyMoveAction("MoveToSection");
    }
  };

  const handleMoveToFolderConfirm = () => {
    if (!p.openEntry) {
      return;
    }
    p.bucket.moveSelectionToFolder(p.openEntry);
  };

  const handleMoveToSectionConfirm = () => {
    if (!isSearchPage || !p.openSection) {
      return;
    }
    p.bucket.moveSelectionToSection(p.openSection);
  };

  const handleCancelMoveToFolderAction = () => {
    p.bucket.cancelAction();

    if (hasChangedEntry || !p.openEntry) {
      p.bucket.clearSelection();
    }
  };

  const handleCopyConfirm = async () => {
    if (!p.openEntry || p.bucket.inProgress) {
      return;
    }
    await p.bucket.copySelection(p.openEntry);
  };

  const handleCancelAction = () => {
    p.bucket.cancelAction();

    if (hasChangedEntry || !p.openEntry) {
      p.bucket.clearSelection();
    }
  };

  const canDelete = p.bucket.canDeleteSelection;
  const canUpdate = p.bucket.canUpdateSelection;
  const count = p.bucket.itemCount;

  return (
    <>
      {p.bucket.action === undefined && (
        <Bucket.Wrap>
          <Bucket.Title>
            <CloseButton
              text={texts.tooltip_clear}
              variant="secondary"
              onClick={p.onClose}
            />
            {intl.formatMessage(texts.title_document, {
              count: count || p.bucket.lastItemCount,
            })}
          </Bucket.Title>
          <Bucket.Actions>
            {canDelete && (
              <Button
                variant="secondary"
                icon="DeleteIcon"
                size={1.125}
                text={texts.tooltip_delete}
                onClick={handleDelete}
              />
            )}
            {p.openEntry?.isSingleDocumentEntry && (
              <Button
                variant="secondary"
                icon="MoveIcon"
                size={1.125}
                text={texts.tooltip_move}
                onClick={handleMoveToFolder}
              />
            )}
            {!p.openEntry?.isSingleDocumentEntry && (
              <Dropdown
                toggle={(ref) => (
                  <Button
                    icon="MoveIcon"
                    size={1.125}
                    variant="secondary"
                    text={commonTexts.move}
                    ref={ref}
                  />
                )}
              >
                <Menu>
                  <MenuItem onClick={handleMoveToSection}>
                    {intl.formatMessage(texts.tooltip_move_to_section)}
                  </MenuItem>
                  <MenuItem onClick={handleMoveToFolder}>
                    {intl.formatMessage(texts.tooltip_move_to_folder)}
                  </MenuItem>
                </Menu>
              </Dropdown>
            )}
            <Button
              variant="secondary"
              icon="CopyIcon"
              size={1.125}
              text={texts.tooltip_copy}
              onClick={() => p.bucket.setAction("Copy")}
            />
          </Bucket.Actions>
        </Bucket.Wrap>
      )}

      {p.bucket.action === "Move" &&
        p.bucket.moveActionSubType === "MoveToFolder" && (
          <Bucket.Wrap attention={!hasChangedEntry}>
            <Bucket.Title>
              <MoveIcon />
              {intl.formatMessage(
                hasChangedEntry
                  ? texts.title_move_document
                  : texts.title_choose_entry,
                { count }
              )}
            </Bucket.Title>
            <Bucket.Actions>
              <Button
                variant="blank"
                text={commonTexts.cancel}
                onClick={handleCancelMoveToFolderAction}
              />
              {canUpdate && hasChangedEntry && (
                <Button
                  variant="secondaryInverted"
                  text={commonTexts.moveHere}
                  onClick={handleMoveToFolderConfirm}
                />
              )}
            </Bucket.Actions>
          </Bucket.Wrap>
        )}

      {p.bucket.action === "Move" &&
        p.bucket.moveActionSubType === "MoveToSection" && (
          <Bucket.Wrap attention={!isSearchPage}>
            <Bucket.Title>
              <MoveIcon />
              {intl.formatMessage(
                isSearchPage
                  ? texts.title_move_document_for_split
                  : texts.title_choose_section_for_split,
                { count }
              )}
            </Bucket.Title>
            <Bucket.Actions>
              <Button
                variant="blank"
                text={commonTexts.cancel}
                onClick={handleCancelAction}
              />
              {canUpdate && isSearchPage && (
                <Button
                  variant="secondaryInverted"
                  text={commonTexts.moveHere}
                  onClick={handleMoveToSectionConfirm}
                />
              )}
            </Bucket.Actions>
          </Bucket.Wrap>
        )}

      {p.bucket.action === "Copy" && (
        <Bucket.Wrap attention={!hasChangedEntry}>
          <Bucket.Title>
            <MoveIcon />
            {intl.formatMessage(
              hasChangedEntry
                ? texts.title_copy_document
                : texts.title_choose_entry_for_copy,
              { count }
            )}
          </Bucket.Title>
          <Bucket.Actions>
            <Button
              variant="blank"
              text={commonTexts.cancel}
              onClick={handleCancelAction}
            />
            {canUpdate && hasChangedEntry && (
              <Button
                variant="secondaryInverted"
                text={commonTexts.copyHere}
                disabled={p.bucket.inProgress}
                onClick={handleCopyConfirm}
              />
            )}
          </Bucket.Actions>
        </Bucket.Wrap>
      )}
    </>
  );
});
