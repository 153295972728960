import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Overlay } from "@web/components";
import { vars } from "@web/styles";
import {
  AccessGroupListModel,
  AccessGroupModel,
} from "@web/models/AccessControlModel";
import { LazyList } from "../LazyList";
import { Button } from "@web/elements/Button";
import { defineMessages, FormattedMessage } from "react-intl";
import { commonTexts } from "@web/translations";

interface IProps {
  accessGroups: AccessGroupListModel;
  disableGroups: Set<UUID>;
  onDone: (selected: AccessGroupModel) => void;
  onCancel: () => void;
}

export const SelectAccessGroupBox = observer(
  ({
    accessGroups: { accessGroups, loadNextPage, loadingStatus, filter },
    disableGroups,
    onDone,
    onCancel,
  }: IProps) => {
    return (
      <Overlay>
        <_wrap>
          <_title>
            <FormattedMessage {...texts.title} />
          </_title>
          <LazyList
            itemSize={35}
            loadingStatus={loadingStatus}
            loadNextPage={loadNextPage}
            renderItem={(index) => (
              <_accessGroup
                text={accessGroups[index].name}
                disabled={disableGroups.has(accessGroups[index].uuid)}
                onClick={() => {
                  onDone(accessGroups[index]);
                }}
              />
            )}
            onQueryChange={filter}
          />
          <_cancelButton text={commonTexts.cancel} onClick={onCancel} />
        </_wrap>
      </Overlay>
    );
  }
);

const _wrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 500px;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
`;

const _title = styled.h3`
  padding: 0;
  margin: 0 0 25px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const _accessGroup = styled(Button).attrs({ variant: "blank" })`
  width: 100%;
  justify-content: left;
`;

const _cancelButton = styled(Button).attrs({ variant: "blank" })`
  margin-top: 20px;
  color: ${vars.primaryLight10};
`;

const texts = defineMessages({
  title: {
    id: "accessControl.selectGroup.title",
    defaultMessage: "Select the group you want to add",
  },
});
