import React, { useState, ChangeEvent } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import {
  TagModel,
  AttributeModel,
  ATTRIBUTE_TYPE_OPTIONS,
  AttributeType,
  WritableFields,
  ClassModel,
} from "@web/models";
import { ActionMenu, ErrorMessage, Input, RadioButton } from "@web/elements";
import { Overlay } from "@web/components";
import { commonTexts } from "@web/translations";
import { vars } from "@web/styles";
import { useConfirmationDialog } from "@web/utils/hooks/useConfirmDialog";
import { EditAttributeOptions } from "./EditAttributeOptions";
import { Header } from "../Header";
import { AddLink } from "../AddLink";
import { Empty } from "../Empty";
import { AttributeMenu } from "./Menus";
import { SettingsBox } from "../Box";

interface Events {
  onBackClick: () => void;
  onCreateAttribute: (
    fields: WritableFields<AttributeModel>,
    parent: TagModel | ClassModel
  ) => void;
}

interface Props extends Events {
  parent: TagModel | ClassModel;
  error?: MessageDescriptor;
  isSaving?: boolean;
  showButton: "back" | "close";
}

export const EditAttributes = observer((p: Props) => {
  const intl = useIntl();

  const [getConfirmation] = useConfirmationDialog();

  const [selectedAttribute, selectAttribute] = useState<AttributeModel>();
  const [addingNewAttribute, addNewAttribute] = useState(false);
  const [editOptions, setEditOptions] = useState<AttributeModel>();
  const [input, setInput] = useState("");
  const [type, setType] = useState<AttributeType>("Date");

  const { parent } = p;

  const title = selectedAttribute
    ? intl.formatMessage(texts.editAttribute)
    : addingNewAttribute
    ? intl.formatMessage(texts.addAttribute)
    : intl.formatMessage(texts.title, { parentTitle: parent.title });

  const handleRename = (att: AttributeModel) => {
    setInput(att.name);
    selectAttribute(att);
  };

  const handleNewAttributeClick = () => {
    setInput("");
    setType("Date");
    addNewAttribute(true);
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSelectChange = (value: string) => {
    setType(value as AttributeType);
  };

  const handleCancel = () => {
    selectAttribute(undefined);
    addNewAttribute(false);
  };

  const handleDelete = async (att: AttributeModel) => {
    const confirmed = await getConfirmation({
      title: texts.deleteAttributeDialogTitle,
      message: texts.deleteAttributeDialogQuestion,
      confirmText: commonTexts.delete,
      warningText: commonTexts.asyncDeleteWarning,
      values: {
        name: att.name,
        entity: "attribute",
      },
    });

    if (confirmed) {
      att.delete(p.parent);
    }
  };

  const handleSave = (e?: React.FormEvent) => {
    e?.preventDefault();
    const trimmed = input.trim();
    if (selectedAttribute) {
      selectedAttribute.update({ name: trimmed });
      selectAttribute(undefined);
    } else if (addingNewAttribute) {
      p.onCreateAttribute({ name: trimmed, type }, p.parent);
      addNewAttribute(false);
    }
  };

  const handleCloseClick = () => {
    if (editOptions) {
      setEditOptions(undefined);
    } else {
      p.onBackClick();
    }
  };

  const canSave = () => {
    const trimmed = input.trim();
    if (selectedAttribute) {
      return selectedAttribute.name !== trimmed && trimmed.length > 0;
    }
    return trimmed.length > 0;
  };

  const isEditing = selectedAttribute || addingNewAttribute;

  return (
    <>
      <SettingsBox>
        <Header
          title={title}
          onButtonClick={handleCloseClick}
          showSpinner={p.isSaving}
          showButton={!isEditing ? p.showButton : undefined}
        />

        {isEditing && (
          <_form onSubmit={handleSave}>
            <_formElements>
              <Input
                autoFocus
                value={input}
                placeholder={
                  selectedAttribute?.name ||
                  intl.formatMessage(texts.placeholder)
                }
                onChange={handleInputChange}
              />
              {addingNewAttribute && (
                <div>
                  <span>{intl.formatMessage(texts.selectType)}</span>
                  {Object.entries(ATTRIBUTE_TYPE_OPTIONS).map(
                    ([value, title]) => (
                      <RadioButton
                        name="type"
                        key={value}
                        value={value}
                        label={intl.formatMessage(title)}
                        checked={type === value}
                        onChange={handleSelectChange}
                      />
                    )
                  )}
                </div>
              )}
            </_formElements>

            <ActionMenu
              direction="vertical"
              applyIsDisabled={!canSave()}
              applyText={
                addingNewAttribute ? commonTexts.add : commonTexts.save
              }
              onApply={handleSave}
              onCancel={handleCancel}
            />
          </_form>
        )}

        {!isEditing && (
          <>
            {parent.attributes.length === 0 && <Empty title={texts.empty} />}
            <_attributeList>
              {parent.attributes.map((att) => (
                <_attributeWrap key={att.name}>
                  <_attribute>
                    <span>{att.name}</span>
                    <span>
                      {intl.formatMessage(ATTRIBUTE_TYPE_OPTIONS[att.type])}
                    </span>
                    <AttributeMenu
                      attribute={att}
                      onRename={() => handleRename(att)}
                      onDelete={() => handleDelete(att)}
                      onOptionsEdit={() => setEditOptions(att)}
                    />
                  </_attribute>
                </_attributeWrap>
              ))}
            </_attributeList>
            {p.error && <ErrorMessage message={intl.formatMessage(p.error)} />}
            <AddLink
              title={texts.addAttribute}
              onClick={handleNewAttributeClick}
            />
          </>
        )}
      </SettingsBox>

      {/** Overlays for different edit operations */}
      {editOptions && (
        <Overlay bg="transparent">
          <EditAttributeOptions
            attribute={editOptions}
            onCloseClick={handleCloseClick}
          />
        </Overlay>
      )}
    </>
  );
});

const _form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 15px;
  :last-child {
    margin-bottom: 10px;
  }
`;

const _formElements = styled.div`
  display: flex;
  flex-direction: column;
  > div:last-child {
    margin-top: 20px;
    margin-left: 5px;
    span {
      padding-bottom: 5px;
      display: inline-block;
    }
  }
`;

const _attributeList = styled.div`
  display: flex;
  overflow-y: auto;
  flex: 1;
  max-height: 380px;
  flex-direction: column;
  padding: 0px 15px;
`;

const _attributeWrap = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 5px 0;
  svg {
    visibility: hidden;
  }
  button {
    :focus {
      svg {
        visibility: visible;
      }
    }
  }
  &:hover {
    svg {
      visibility: visible;
    }
  }
`;

const _attribute = styled.div<{ selected?: boolean }>`
  border: none;
  padding: 10px;
  border-radius: 5px;
  background: ${vars.info};
  display: flex;
  flex: 1;
  text-align: left;
  align-items: center;
  word-break: break-all;
  span:first-of-type {
    flex-grow: 1;
    padding-left: 10px;
    font-size: 1.2em;
  }
  svg {
    flex-shrink: 0;
  }
  span:last-of-type {
    opacity: 0.6;
    margin: 0 15px;
    flex-shrink: 0;
  }
  &:hover {
    background: ${vars.info};
  }
`;

const texts = defineMessages({
  title: {
    id: "settings.attributes.title",
    defaultMessage: "{parentTitle}: properties",
  },
  empty: {
    id: "settings.attributes.empty",
    defaultMessage: "No properties added",
  },
  addAttribute: {
    id: "settings.attributes.add",
    defaultMessage: "Add property",
  },
  editAttribute: {
    id: "settings.attributes.edit",
    defaultMessage: "Edit property",
  },
  selectType: {
    id: "settings.attributes.selecttype",
    defaultMessage: "Select property type",
  },
  placeholder: {
    id: "settings.attributes.placeholder",
    defaultMessage: "Property name",
  },
  deleteAttributeDialogTitle: {
    id: "settings.attributes.deletetagdialog.title",
    defaultMessage: "Delete property",
  },
  deleteAttributeDialogQuestion: {
    id: "settings.attributes.deletetagdialog.question",
    defaultMessage:
      "This will permanently remove the property <em>{name}</em> from where it has been applied. Are you sure you want to proceed?",
  },
});
