import React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { LOGOUT_PATH } from "@web/utils/paths";
import { Button } from "@web/elements/Button";
import { Dialog } from "../Dialog";
import LanguageSwitcher from "./LanguageSwitcher";

interface UserProfileDialogProps {
  userFullName: string;
  onClose: (result: { wasConfirmed: boolean }) => void;
}

export const UserProfileDialog: React.FC<UserProfileDialogProps> = ({
  userFullName,
  onClose,
}) => {
  const intl = useIntl();

  const handleCloseDialog = () => onClose({ wasConfirmed: false });
  const handleLogOut = () => (window.location.href = LOGOUT_PATH);

  return (
    <Dialog
      title={{ defaultMessage: userFullName }}
      onClickOutside={handleCloseDialog}
      onEscape={handleCloseDialog}
    >
      <_div>
        <_section>
          <LanguageSwitcher />
        </_section>
        <_section>
          <_button
            text={intl.formatMessage(texts.logOut)}
            variant="primary"
            onClick={handleLogOut}
          />
        </_section>
      </_div>
    </Dialog>
  );
};

const _div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 8px;
  line-height: 1;
`;

const _section = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const _button = styled(Button)`
  width: fit-content;
`;

const texts = defineMessages({
  logOut: {
    id: "logout",
    defaultMessage: "Log out",
  },
});
