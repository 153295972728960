import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { components, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { defineMessages, useIntl } from "react-intl";
import { IClassificationNodeExtended } from "@web/api/Integration/types";
import { Overlay } from "@web/components";
import { ActionMenu } from "@web/elements";
import { vars } from "@web/styles";
import { selectTheme } from "@web/styles/DMReactSelectStyle";
import { commonTexts, MessageWithValues } from "@web/translations";

interface Events {
  findClassifications: (
    query: string
  ) => Promise<IClassificationNodeExtended[]>;
  onSelect: (classification: IClassificationNodeExtended) => void;
  onCancel: () => void;
}

interface Props extends Events {
  title?: MessageWithValues;
}

interface SelectOption {
  label: string;
  value: IClassificationNodeExtended;
  isDisabled: boolean;
}

export const SelectClassificationBox: React.FC<Props> = observer((props) => {
  const intl = useIntl();
  const [selectedClass, setSelectedClass] =
    useState<IClassificationNodeExtended>();
  const canSave = selectedClass !== undefined;

  function handleSave(doSave: boolean) {
    const { onSelect, onCancel } = props;
    if (doSave && selectedClass) {
      onSelect(selectedClass);
    } else {
      onCancel();
    }
  }

  function handleSelectChange(selected: SingleValue<SelectOption>) {
    setSelectedClass(selected?.value);
  }

  async function loadOptions(inputValue: string) {
    const res = await props.findClassifications(inputValue);
    return res.map((cl) => ({
      value: cl,
      label: cl.title,
      subLabel: cl.sections.map((section) => section.title).join(", "),
      isDisabled: !cl.effectivePermissions.includes("Update"),
    }));
  }

  return (
    <Overlay>
      <_wrap>
        <h3>
          {props.title
            ? intl.formatMessage(props.title, props.title.values)
            : intl.formatMessage(texts.title)}
        </h3>
        <_inputWrap>
          <AsyncSelect
            autoFocus
            isClearable
            theme={selectTheme}
            placeholder={intl.formatMessage(texts.typeToFindList)}
            loadOptions={loadOptions}
            onChange={handleSelectChange}
            loadingMessage={({ inputValue }) =>
              intl.formatMessage(texts.lookingFor, {
                value: inputValue,
              })
            }
            noOptionsMessage={({ inputValue }) =>
              inputValue.length > 0
                ? intl.formatMessage(commonTexts.noMatches)
                : null
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: (props) => (
                <components.Option {...props}>
                  <div>{props.data.label}</div>
                  <_optionSubtitle>
                    {intl.formatMessage(texts.belongsToSections, {
                      sections: props.data.subLabel,
                    })}
                  </_optionSubtitle>
                </components.Option>
              ),
            }}
          />
        </_inputWrap>
        <ActionMenu
          onApply={() => handleSave(true)}
          onCancel={() => handleSave(false)}
          applyIsDisabled={!canSave}
          applyText={commonTexts.select}
        />
      </_wrap>
    </Overlay>
  );
});

const _wrap = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 350px;
  background: ${vars.content};
  color: ${vars.contentFg};
  border-radius: 3px;
  padding: 20px;
  font-size: 13px;
  input {
    border-radius: 3px;
  }
  h3 {
    margin: 10px 0px;
    font-size: 16px;
  }
`;

const _inputWrap = styled.div`
  margin: 10px 0px;
`;

const _optionSubtitle = styled.div`
  font-size: 0.8em;
  padding-top: 2px;
  color: ${vars.dark55};
`;

const texts = defineMessages({
  title: {
    id: "classification.selectbox.title",
    defaultMessage: "Find list",
  },
  lookingFor: {
    id: "classification.selectbox.lookingfor",
    defaultMessage: 'Looking for "{value}"',
  },
  typeToFindList: {
    id: "classification.selectbox.typetofindlist",
    defaultMessage: "Type to find a list…",
  },
  belongsToSections: {
    id: "classification.selectbox.belongstosections",
    defaultMessage: "Belongs to sections: {sections}",
  },
});
