import React from "react";
import styled from "styled-components";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@web/components/Dialog";
import { Button } from "@web/elements/Button";
import { commonTexts } from "@web/translations";

export type UploadChoice = "multiple-entries" | "single-entry";

interface UploadDialogProps {
  onCancel: () => void;
  onConfirm: (choice: UploadChoice) => void;
}

export const UploadDialog = (p: UploadDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog title={texts.title}>
      <p>{intl.formatMessage(texts.body)}</p>
      <_actions>
        <Button
          text={texts.createFolderButton}
          variant="primary"
          onClick={() => p.onConfirm("single-entry")}
        />

        <Button
          text={texts.singleFilesButton}
          variant="primary"
          onClick={() => p.onConfirm("multiple-entries")}
        />
      </_actions>
      <_cancel>
        <Button text={commonTexts.cancel} onClick={p.onCancel} />
      </_cancel>
    </Dialog>
  );
};

const _actions = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;

  > button {
    font-size: 1rem;
    width: 100%;
  }
`;

const _cancel = styled.div`
  text-align: center;
`;

const texts = defineMessages({
  title: {
    id: "upload.dropzone.dialog.title",
    defaultMessage: "Create folder?",
  },
  body: {
    id: "upload.dropzone.dialog.body",
    defaultMessage:
      "You're uploading multiple documents. Collect them in a folder if they're closely related (e.g. contract with attachment, photo album, meeting documents etc.)",
  },
  singleFilesButton: {
    id: "upload.dropzone.dialog.singleFilesButton",
    defaultMessage: "Just upload",
  },
  createFolderButton: {
    id: "upload.dropzone.dialog.createFolderButton",
    defaultMessage: "Create folder",
  },
});
