import { defineMessages } from "react-intl";

export const texts = defineMessages({
  tooltip_clear: {
    id: "selection.tooltip.clear",
    defaultMessage: "Clear selection",
  },
  tooltip_move: {
    id: "selection.tooltip.move",
    defaultMessage: "Move",
  },
  tooltip_move_to_section: {
    id: "selection.tooltip.move.to.section",
    defaultMessage: "Move to section",
  },
  tooltip_move_to_folder: {
    id: "selection.tooltip.move.to.folder",
    defaultMessage: "Move to folder",
  },
  tooltip_copy: {
    id: "selection.tooltip.copy",
    defaultMessage: "Copy",
  },
  tooltip_delete: {
    id: "selection.tooltip.delete",
    defaultMessage: "Delete",
  },
  tooltip_tag: {
    id: "selection.tooltip.tag",
    defaultMessage: "Tag",
  },
  title_entry: {
    id: "selection.title.entry",
    defaultMessage:
      "{count} {count, plural, one {item} other {items}} selected",
  },
  title_document: {
    id: "selection.title.document",
    defaultMessage:
      "{count} {count, plural, one {document} other {documents}} selected",
  },
  title_tags: {
    id: "selection.title.tags",
    defaultMessage: "Tag {count} {count, plural, one {item} other {items}}",
  },
  title_choose_section: {
    id: "selection.title.choose_section",
    defaultMessage:
      "Open a different section to move {count} {count, plural, one {item} other {items}}",
  },
  title_choose_section_for_split: {
    id: "selection.title.choose_section_for_split",
    defaultMessage:
      "Go to the main page and choose a section to move {count} {count, plural, one {item} other {items}}",
  },
  title_choose_entry: {
    id: "selection.title.choose_entry",
    defaultMessage:
      "Open a different or new folder to move {count} {count, plural, one {document} other {documents}}",
  },
  title_choose_entry_for_copy: {
    id: "selection.title.choose.entry.for.copy",
    defaultMessage:
      "Open a different or a new folder to copy {count} {count, plural, one {document} other {documents}}",
  },
  title_move_entry: {
    id: "selection.title.move_entry",
    defaultMessage:
      "Move {count} {count, plural, one {item} other {items}} to this section",
  },
  title_move_document: {
    id: "selection.title.move_document",
    defaultMessage:
      "Move {count} {count, plural, one {document} other {documents}} to this folder",
  },
  title_move_document_for_split: {
    id: "selection.title.move_document_for_split",
    defaultMessage:
      "Move {count} {count, plural, one {document} other {documents}} to this section",
  },
  title_copy_document: {
    id: "selection.title.copy.document",
    defaultMessage:
      "Copy {count} {count, plural, one {document} other {documents}} to this folder",
  },
  dialog_move_entry_title: {
    id: "selection.dialog.move_entry.title",
    defaultMessage: "Move {count} {count, plural, one {item} other {items}}",
  },
  dialog_delete_title: {
    id: "selection.dialog.delete.title",
    defaultMessage: "Are you sure?",
  },
  dialog_delete_entry_message: {
    id: "selection.dialog.delete_entry.message",
    defaultMessage:
      "This will delete {count} {count, plural, one {item} other {items}}",
  },
  dialog_delete_document_message: {
    id: "selection.confirm.delete_document.message",
    defaultMessage:
      "This will delete {count} {count, plural, one {document} other {documents}}",
  },
  noAccess: {
    id: "selection.noaccess",
    defaultMessage: "You don't have access to modify the selected items.",
  },
  move_documents_dialog_confirm_title: {
    id: "selection.move.documents.to.section.confirm.dialog.title",
    defaultMessage: "Move documents to section?",
  },
  move_documents_dialog_confirm_message: {
    id: "selection.move.documents.to.section.confirm.dialog.message",
    defaultMessage: "Note that the current tags and activity may be lost.",
  },
});
